export class Behaviour
{
}


export interface Initializable
{
	// always called at global initialize
	initialize(): void
}

export interface Enterable
{
	enter(): void;
}

export interface EnterableAsync
{
	enterAsync(): Promise<void>;
	enterAsyncDone(): void;
}

export interface Updatable
{
	update(dt: number): void
}

export interface Exitable
{
	exit(): void;
}

export interface ExitableAsync
{
	exitAsync(): Promise<void>;
}