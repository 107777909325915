if (process.env.NODE_ENV !== 'development')
	console.log("running WARF");
else
	console.log("running WARF in development mode");

import appConfig from './config.yaml';
import timelineData from './assets/timeline.yaml';
import animationsData from './assets/animations.yaml';
import timelineSfxs from './assets/timelineSfxs.yaml';
// import * as services from './scripts/services/*.ts';
import * as behaviours from './scripts/behaviours/*.ts';

import * as locstrFiles from './assets/loc/*.yaml'

import { AppState, AppStateMachine } from './scripts/AppStateMachine';
import { DesktopXRProvider, XR8Provider, XRProvider } from './scripts/XR8Provider';
import { Behaviour } from './scripts/BehaviourInterfaces';

import { Debug } from './scripts/Debug';
import { updateTimers } from './scripts/Utils';
import { Localization } from './scripts/Localization';
import { Device } from './scripts/Compatibility';
import { DesktopPermissions, Permissions } from './scripts/Permissions';
import { ThreeJsRoot } from './scripts/ThreeJsRoot';
import { AppConfig } from './scripts/AppConfig';
import { PermissionsHelper } from './scripts/PermissionsHelper';
import { fromEvent, Subscription, take } from 'rxjs';
import { PortraitChecker } from './scripts/PortraitChecker';
import { PauseManager } from './scripts/PauseManager';
import { Timeline } from './scripts/Timeline';
import { Sfx } from './scripts/Sfx';
import { AnimationsPlayer } from './scripts/AnimationsPlayer';
import { VolumetricVideo } from './scripts/behaviours/VolumetricVideo';

let now: number = 0;

export let xrService: XRProvider;

const startElementsSubs: Subscription[] = [];

declare const XR8: any;

let time: number = 0;

let anims: HTMLCollectionOf<Element>

export let lowPower: boolean = false;

function init(): void
{
	anims = document.getElementsByClassName("snow-anim");
	requestAnimationFrame(animFrame);

	// ServiceLocator.setServices(services);

	// var testService = ServiceLocator.get<TestService>("TestService");

	// console.log(testService);

	// ServiceLocator.get<TestService>("TestService")?.initialize();
	// ServiceLocator.get<TestService>("TestService")?.test();


	window.addEventListener("DOMContentLoaded", () =>
	{
		onDOMContentLoaded();
	});

	// window.addEventListener("load", () =>
	// {
	// 	onLoad();
	// });

	window.addEventListener("xrloaded", () =>
	{
		initialize();
	});

	window.addEventListener("beforeunload", () =>
	{
		console.log("beforunload!");
	});

	window.addEventListener("unload", () =>
	{
		AnimationsPlayer.dispose();
		AppStateMachine.dispose();
		Sfx.dispose();
		XR8.stop();
		console.log("unload!");
	});
}

async function onDOMContentLoaded(): Promise<void>
{
	console.log("[main] DOMContentLoaded");

	AppConfig.initialize(appConfig);
	ThreeJsRoot.initialize();
	Localization.initialize(locstrFiles);
	Timeline.initialize(timelineData);
	AnimationsPlayer.initialize(animationsData);
	Sfx.initialize(timelineSfxs);

	for (const [k, v] of Object.entries(behaviours))
	{
		var value = v as any;
		var p = value[k].prototype;

		if (p.doNotLoadBehaviour)
			continue;

		console.log(p);

		var state: AppState | undefined;
		if (p.appState != null)
		{
			console.log("found appState! " + p.appState);
			state = AppStateMachine.getState(p.appState);
		}
		else
		{
			console.log("no appState");
			state = AppStateMachine.getState("null");
		}

		console.log(state);
		state?.add(p as Behaviour);
	}

	AppStateMachine.initialize(appConfig);



	// if (process.env.NODE_ENV !== 'development')
	// 	return;

	// Debug.initialize();
}

async function initialize(): Promise<void>
{
	console.log("[main] initialize after xrloaded");

	await Sfx.preload("silence.mp3");
	Sfx.play("silence.mp3");
	await Sfx.preload("click.wav");

	console.log(AppConfig.get().startXRElementIDs);

	console.log("READY FOR STARTXR");

	for (let i = 0; i < AppConfig.get().startXRElementIDs.length; i++)
	{
		const id = AppConfig.get().startXRElementIDs[i];
		const element = document.getElementById(id);
		if (element === null)
			continue;
		const sub = fromEvent(element, "click").subscribe(() =>
		{
			Sfx.play("click.wav");
			startXR();
		});
		startElementsSubs.push(sub);
	}
}

async function startXR(): Promise<void>
{
	if (xrService !== undefined && xrService.ready())
	{
		AppStateMachine.requestNextState();
		return;
	}

	document.getElementById("camera-loading")?.classList.remove("hidden");


	console.log("[main] startXR");
	// for (let i = 0; i < startElementsSubs.length; i++) {
	// 	startElementsSubs[i].unsubscribe();
	// }

	var compatible = Device.isCompatible();
	var estimation = Device.estimation();

	var permissions = compatible ? new Permissions() : new DesktopPermissions();

	var hasMotion = await permissions.Motion(estimation);
	var hasOrientation = await permissions.Orientation(estimation);

	if (hasMotion === false || hasOrientation === false)
	{
		PermissionsHelper.promptUserToChangeBrowserMotionSettings(permissions, estimation);
		return;
	}

	var hasCamera = await permissions.Camera(estimation);
	if (hasCamera === false)
	{
		PermissionsHelper.promptUserToChangeBrowserCameraSettings(permissions, estimation);
		return;
	}

	lowPower = false;
	// if (estimation.os === "Android")
	// {
	// 	if (parseInt(estimation.osVersion) <= 10)
	// 	{
	// 		lowPower = true;
	// 	}
	// }
	// else if (estimation.os === "iOS")
	// {
	// 	if (estimation.model === "iPhone 6/6s/7/8")
	// 	{
	// 		lowPower = true;
	// 	}
	// }

	console.log("LOWPOWER: " + lowPower);

	console.log("starting preloads");
	await AnimationsPlayer.loadModels(lowPower);
	await Sfx.preloadTimelineSfxs(lowPower);
	console.log("preloads done");

	var renderCanvas = document.getElementById("render-canvas") as HTMLCanvasElement;
	xrService = compatible ? new XR8Provider() : new DesktopXRProvider();

	xrService.initialize(renderCanvas);

	AppStateMachine.requestNextState();
}

// function onLoad(): void
// {
// 	console.log("[main] load");
// }

function animFrame(ts: number): void
{
	let dt = (ts - now) * 0.001; // ms to seconds
	time += dt;
	if (PauseManager.isPaused() === false && PortraitChecker.isLandscape() === false)
	{
		AppStateMachine.update(dt);
		Sfx.update(dt);
		updateTimers(dt);
		xrService?.update(dt);

		for (var i = 0; i < anims.length; i++) 
		{
			if (anims[i].classList.contains("hidden"))
				continue;
			var htmlElement = anims[i] as HTMLElement;
			var pos = (time * 33);
			if (pos > 99999)
				pos = 0;
			htmlElement.style.backgroundPositionY = pos + "px";
		}
	}
	now = ts;
	requestAnimationFrame(animFrame);
}

init();

console.log("initialized WARF");