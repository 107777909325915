import * as THREE from "three";
import { xrService } from "../../main";
import { Behaviour, Enterable, Exitable, Initializable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { Timeline } from "../Timeline";
import { VolumetricVideo } from "./VolumetricVideo";

@InAppState("scanning")
// @DoNotLoadBehaviour()
export class Snow extends Behaviour implements Initializable, Enterable, Exitable, Updatable
{
	private parameters: any[] = [];
	private materials: THREE.PointsMaterial[] = [];
	private group: THREE.Group;

	private time: number = 0;

	public initialize(): void
	{
		this.group = new THREE.Group();

		const geometry = new THREE.BufferGeometry();
		const vertices = [];

		const textureLoader = new THREE.TextureLoader();

		const sprite1 = textureLoader.load('textures/snowflake1.png');
		// const sprite2 = textureLoader.load('textures/sprites/snowflake2.png');
		// const sprite3 = textureLoader.load('textures/sprites/snowflake3.png');
		// const sprite4 = textureLoader.load('textures/sprites/snowflake4.png');
		// const sprite5 = textureLoader.load('textures/sprites/snowflake5.png');

		for (let i = 0; i < 10000; i++)
		{

			const x = Math.random() * 40 - 20;
			const y = Math.random() * 40 - 20;
			const z = Math.random() * 40 - 20;

			vertices.push(x, y, z);

		}

		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

		this.parameters = [
			[[1.0, 0.2, 0.5], sprite1, 0.2],
			[[0.95, 0.1, 0.5], sprite1, 0.15],
			[[0.90, 0.05, 0.5], sprite1, 0.1],
			[[0.85, 0, 0.5], sprite1, 0.08],
			[[0.80, 0, 0.5], sprite1, 0.05]
		];

		this.materials = [];

		for (let i = 0; i < this.parameters.length; i++)
		{
			const color = this.parameters[i][0];
			const sprite = this.parameters[i][1];
			const size = this.parameters[i][2];

			var mat = new THREE.PointsMaterial({ size: size, map: sprite, blending: THREE.AdditiveBlending, depthTest: false, transparent: true });
			this.materials.push(mat);
			mat.color.setHSL(color[0], color[1], color[2]);

			const particles = new THREE.Points(geometry, mat);

			particles.rotation.x = Math.random() * 6;
			particles.rotation.y = Math.random() * 6;
			particles.rotation.z = Math.random() * 6;

			console.log(particles);

			this.group.add(particles);
		}

		this.time = 0;

		this.group.visible = true;

		// Timeline.EventObservable().subscribe(e =>
		// {
		// 	if (e === "snow")
		// 	{
		// 		this.group.visible = true;
		// 	}
		// });
	}

	public enter(): void
	{
		xrService.getSceneProvider().getSceneBundle().scene.add(this.group);
		this.group.position.z = -5;
		// for (let i = 0; i < this.materials.length; i++)
		// {
		// 	this.materials[i].opacity = 0;
		// }
	}

	public exit(): void
	{
		// this.group.visible = false;
		xrService.getSceneProvider().getSceneBundle().scene.remove(this.group);
	}

	public update(dt: number): void
	{
		if (this.group.visible === false) //|| VolumetricVideo.checkBufferReady() === false)
			return;

		this.time += dt;

		for (let i = 0; i < this.group.children.length; i++)
		{
			const object = this.group.children[i];
			// console.log(object);

			if (object instanceof THREE.Points)
			{
				var speed = (i < 4 ? i + 1 : - (i + 1));
				speed *= 0.33;
				// object.rotation.x = this.time * speed;
				object.rotation.y = this.time * speed;
				object.rotation.z = this.time * speed;
				// if (i == 0)
				// {
				// 	console.log(object.rotation);

				// }
			}

		}

		for (let i = 0; i < this.materials.length; i++)
		{
			const color = this.parameters[i][0];

			const h = (360 * (color[0] + this.time) % 360) / 360;
			this.materials[i].color.setHSL(h, color[1], color[2]);

			// if (this.materials[i].opacity > 1)
			// 	this.materials[i].opacity = 1;
			// else
			// 	this.materials[i].opacity += dt;
		}
	}
}