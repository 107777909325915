import { Observable, Subject } from "rxjs";

export class PauseManager
{
	private static isPausedInternal: boolean = false;

	private static pausedSubject: Subject<boolean> = new Subject<boolean>();

	public static pausedObservable(): Observable<boolean>
	{
		return this.pausedSubject;
	}

	public static isPaused(): boolean
	{
		return this.isPausedInternal;
	}

	public static setPaused(paused: boolean): void
	{
		this.isPausedInternal = paused;
		this.pausedSubject.next(this.isPausedInternal);
	}

	public static togglePaused(): void
	{
		this.setPaused(!this.isPausedInternal);
	}
}