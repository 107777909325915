import * as THREE from "three";
import { Mesh, MeshBasicMaterial, ShadowMaterial } from "three";
import { ThreeJsModelLoader } from "./ThreeJsModelLoader";
import { ThreeJsRoot } from "./ThreeJsRoot";
import { Timeline } from "./Timeline";

export class AnimationsPlayer
{
	private static animations: Animation[];

	public static initialize(animationsData: AnimationEntry[]): void
	{
		this.animations = [];
		for (let i = 0; i < animationsData.length; i++)
		{
			console.log(animationsData[i]);
			var anim = new Animation(animationsData[i]);
			this.animations.push(anim);
		}
	}

	public static async loadModels(lowPower: boolean): Promise<void>
	{
		if (this.animations.length === 0 || lowPower)
			return;
		for (let i = 0; i < this.animations.length; i++)
		{
			console.log("loading " + this.animations[i].entry.path);
			var obj = await ThreeJsModelLoader.loadFromUrl(this.animations[i].entry.path);
			console.log("loaded!");
			this.animations[i].setObject(obj);

			ThreeJsRoot.getTargetRoot(this.animations[i].entry.imageTarget).add(obj);

			const position = this.animations[i].entry.localPosition;
			if (position !== undefined)
				obj.position.set(position.x, position.y, position.z);
			const scale = this.animations[i].entry.localScale;
			if (scale !== undefined)
				obj.scale.set(scale.x, scale.y, scale.z);
			const rotation = this.animations[i].entry.localRotation;
			if (rotation !== undefined)
				obj.rotation.set(rotation.x, rotation.y, rotation.z);
			const visibleStartState = this.animations[i].entry.visibleStartState;
			if (visibleStartState !== undefined)
				obj.visible = visibleStartState;
		}

		Timeline.EventObservable().subscribe(e =>
		{
			for (let i = 0; i < this.animations.length; i++)
			{
				if (this.animations[i].entry.event === e)
				{
					this.animations[i].object.visible = true;
					this.animations[i].shouldUpdate = true;
					this.animations[i].action.play();
					console.log("playing " + this.animations[i].entry.event);
				}
			}
		});
	}

	public static getAnimation(name: string): Animation | null
	{
		for (let i = 0; i < this.animations.length; i++)
		{
			if (this.animations[i].entry.name === name)
				return this.animations[i];
		}

		return null;
	}

	public static update(dt: number)
	{
		for (let i = 0; i < this.animations.length; i++)
		{
			this.animations[i].update(dt);
		}
	}

	public static reset(): void
	{
		for (let i = 0; i < this.animations.length; i++)
		{
			// this.animations[i].shouldUpdate = false;
			// this.animations[i].action.stop();
			// const visibleStartState = this.animations[i].entry.visibleStartState;
			// if (visibleStartState !== undefined)
			// 	this.animations[i].object.visible = visibleStartState;
			this.animations[i].reset();
		}
	}

	public static dispose(): void
	{
		console.log("disposing anims!");
		for (let i = 0; i < this.animations.length; i++)
		{
			this.animations[i].dispose();
		}
	}
}

interface AnimationEntry
{
	name: string;
	event: string;
	path: string;
	imageTarget: string;
	localPosition: { x: number, y: number, z: number }
	localScale: { x: number, y: number, z: number }
	localRotation: { x: number, y: number, z: number }
	visibleStartState: boolean
	timeScale: number
	renderOrder: number
	hideAfter: number
	hideWhenDone: boolean;
}

export class Animation
{
	public entry: AnimationEntry;
	public object: THREE.Object3D;
	public mixer: THREE.AnimationMixer;
	public action: THREE.AnimationAction;
	public shouldUpdate: boolean;
	public lifetime: number;

	constructor(entry: AnimationEntry) 
	{
		this.entry = entry;
		this.lifetime = 0;
		if (this.entry.hideWhenDone === undefined || this.entry.hideWhenDone === null)
			this.entry.hideWhenDone = false;
	}

	public setObject(object: THREE.Object3D)
	{
		this.object = object;
		if (this.entry.renderOrder !== undefined && this.entry.renderOrder !== null)
			this.object.renderOrder = this.entry.renderOrder;
		this.mixer = new THREE.AnimationMixer(this.object);
		this.action = this.mixer.clipAction(this.object.animations[0]);
		var ts = this.entry.timeScale;
		if (ts === undefined || ts === null)
			ts = 1;
		this.mixer.timeScale = ts;

		var shadowObj = this.object.getObjectByName("shadow") as Mesh;
		if (shadowObj !== undefined && shadowObj !== null)
		{
			shadowObj.material.dispose();
			shadowObj.material = new THREE.ShadowMaterial({
				opacity: 0.7,
			});
		}

		// this.action.play();
		// this.mixer.update(0.033);
	}

	public update(dt: number): void
	{
		if (this.shouldUpdate === false || this.mixer === undefined)
			return;

		if (this.mixer.time >= this.action.getClip().duration)
		{
			if (this.entry.hideWhenDone)
			{
				this.stop(false);
				return;
			}
		}

		if (this.entry.hideAfter !== undefined && this.entry.hideAfter !== null)
		{
			if (this.entry.hideAfter > 0 && this.lifetime > this.entry.hideAfter)
			{
				this.stop(false);
				return;
			}
		}


		this.mixer.update(dt);
		this.lifetime += dt;
	}

	public stop(visibleState: boolean): void
	{
		this.action.stop();
		this.shouldUpdate = false;
		this.object.visible = visibleState;
	}

	public reset(): void
	{
		if (this.action === undefined)
			return;
		this.lifetime = 0;
		this.shouldUpdate = false;
		this.action.stop();
		this.mixer.setTime(0);
		const visibleStartState = this.entry.visibleStartState;
		if (visibleStartState !== undefined)
			this.object.visible = visibleStartState;
	}

	public dispose(): void
	{
		this.object.traverse(obj =>
		{
			// console.log(obj.material?.map);
			// console.log(obj.material);
			// console.log(obj.geometry);
			if (obj.material?.map?.dispose !== undefined)
			{
				console.log("disposing " + obj.material?.map);
				obj.material?.map?.dispose();
			}
			if (obj.material?.dispose !== undefined)
			{
				console.log("disposing " + obj.material);
				obj.material?.dispose();
			}
			if (obj.geometry?.dispose !== undefined)
			{
				console.log("disposing " + obj.geometry);
				obj.geometry?.dispose();
			}
		});
	}
}