export function InAppState(name: string)
{
	return function(ctor: Function){
		ctor.prototype.appState = name;
	}
}

export function DoNotLoadBehaviour()
{
	return function(ctor: Function){
		ctor.prototype.doNotLoadBehaviour = true;
	}
}

export function WindowAccess()
{
	return function(ctor: Function){
		var name = ctor.name;
		window[name] = ctor;
	}
}