// import THREE from "three";

import * as THREE from "three";
import { WebGLRenderer } from "three";
import { ThreeJsRoot } from "./ThreeJsRoot";

declare const XR8: any;

export interface SceneBundle
{
	scene: THREE.Scene;
	camera: THREE.Camera;
	renderer: THREE.Renderer;
	cameraTexture: THREE.Texture;
}

export interface SceneProvider
{
	getSceneBundle(): SceneBundle;
	getRendererExtensions(): THREE.WebGLExtensions | null;
}

export class NullSceneProvider implements SceneProvider
{
	getSceneBundle(): SceneBundle
	{
		throw new Error("NullSceneProvider accessed.");
	}
	getRendererExtensions(): THREE.WebGLExtensions
	{
		throw new Error("NullSceneProvider accessed.");
	}
}

export class DesktopSceneProvider implements SceneProvider, SceneBundle
{
	public scene: THREE.Scene;
	public camera: THREE.Camera;
	public renderer: THREE.Renderer;
	public cameraTexture: THREE.Texture;

	constructor(renderCanvas: HTMLCanvasElement) 
	{
		this.scene = new THREE.Scene();
		this.scene.add(ThreeJsRoot.getRoot());

		this.camera = new THREE.PerspectiveCamera();
		this.renderer = new THREE.WebGLRenderer({
			canvas: renderCanvas,
			alpha: false,
		});

		this.cameraTexture = new THREE.Texture(renderCanvas);

		this.onResize();
		window.addEventListener("resize", (event) =>
		{
			this.onResize();
		});
	}

	getRendererExtensions(): THREE.WebGLExtensions | null
	{
		if (this.renderer === undefined || this.renderer === null)
			return null;
		return (<WebGLRenderer>this.renderer).extensions;
	}

	getSceneBundle(): SceneBundle
	{
		return this;
	}

	private onResize(): void
	{
		var width = window.innerWidth;
		var height = window.innerHeight;

        (<THREE.PerspectiveCamera>this.camera).aspect = width / height;
		(<THREE.PerspectiveCamera>this.camera).updateProjectionMatrix();
		this.renderer.setSize(width, height);
	}
}

export class XR8SceneProvider implements SceneProvider
{
	getSceneBundle(): SceneBundle
	{
		return XR8.Threejs.xrScene();
	}

	getRendererExtensions(): THREE.WebGLExtensions | null
	{
		var renderer = this.getSceneBundle().renderer as WebGLRenderer;
		return renderer.extensions;
	}
}