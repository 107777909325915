import { Behaviour, Enterable, EnterableAsync } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { wait } from "../Utils";

@InAppState("stateB")
@DoNotLoadBehaviour()
export class SomeClass extends Behaviour implements Enterable, EnterableAsync
{
	public enter(): void
	{
		console.log("i am someclass");
	}

	public async enterAsync(): Promise<void>
	{
		console.log("starting wait")
		await wait(1);
		console.log("i wait one second!");
	}
}