import { XR8DeviceEstimation } from "./XR8Interfaces";

export interface DevicePermissions
{
	Motion(estimation: XR8DeviceEstimation): Promise<boolean>;
	Orientation(estimation: XR8DeviceEstimation): Promise<boolean>;
	Camera(estimation: XR8DeviceEstimation): Promise<boolean>;
	removeMotionAndOrientation(): void;
}

export class DesktopPermissions implements DevicePermissions
{
	Motion(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		return Promise.resolve<boolean>(true);
	}
	Orientation(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		return Promise.resolve<boolean>(true);
	}
	Camera(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		return Promise.resolve<boolean>(true);
	}
	
	removeMotionAndOrientation(): void
	{
		
	}
}

export class Permissions implements DevicePermissions
{
	public Motion(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		if (estimation.os !== "iOS")
		{
			return Promise.resolve<boolean>(true);
		}
		return new Promise<boolean>((resolve, reject) =>
		{
			console.log("[Permissions] Requesting Motion permission...");
			if (typeof (DeviceMotionEvent as any).requestPermission === 'function')
			{
				(DeviceMotionEvent as any).requestPermission()
					.then((permissionState: string) =>
					{
						if (permissionState === 'granted')
						{
							console.log("[Permissions] Motion permission granted!");
							window.addEventListener('devicemotion', this.motionFunc);
							resolve(true);
						}
						else
						{
							console.log("[Permissions] Motion permission not granted?");
							resolve(false);
						}
					})
					.catch((err: DOMException) =>
					{
						console.error(err.name + " / " + err.code + " / " + err.message);
						//reject(false);
						resolve(false);
					});
			} else
			{
				console.log("[Permissions] Something wrong with Motion permission");
				//reject(false);
				resolve(false);
				// handle regular non iOS 13+ devices
			}
		});
	}

	private motionFunc()
	{

	}

	public Orientation(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		if (estimation.os !== "iOS")
		{
			return Promise.resolve<boolean>(true);
		}
		return new Promise<boolean>((resolve, reject) =>
		{
			console.log("[Permissions] Requesting Orientation permission...");
			if (typeof (DeviceOrientationEvent as any).requestPermission === 'function')
			{
				return (DeviceOrientationEvent as any).requestPermission()
					.then((permissionState: string) =>
					{
						if (permissionState === 'granted')
						{
							console.log("[Permissions] Orientation permission granted!");
							window.addEventListener('deviceorientation', this.orientFunc);
							resolve(true);
						}
						else
						{
							console.log("[Permissions] Motion permission not granted?");
							resolve(false);
						}
					})
					.catch((err: DOMException) =>
					{
						console.error(err.name + " / " + err.code + " / " + err.message);
						//reject(false);
						resolve(false);
					});
			} else
			{
				console.log("[Permissions] Something wrong with Orientation permission");
				//reject(false);
				resolve(false);
				// handle regular non iOS 13+ devices
			}
		});
	}

	private orientFunc()
	{

	}

	public removeMotionAndOrientation()
	{
		window.removeEventListener('devicemotion', this.motionFunc);
		window.removeEventListener('deviceorientation', this.orientFunc);
	}

	public Camera(estimation: XR8DeviceEstimation): Promise<boolean>
	{
		return new Promise<boolean>((resolve, reject) =>
		{
			navigator.mediaDevices.getUserMedia(
				// constraints
				{
					video: true,
					audio: false
				}
			)
				.then((stream) =>
				{
					for (let i = 0; i < stream.getTracks().length; i++)
					{
						stream.getTracks()[i].stop();
					}
				})
				.catch((reason) =>
				{
					resolve(false);
				})
				.finally(() =>
				{
					resolve(true);
				});
		});
	}
}