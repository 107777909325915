import * as THREE from "three";
import { sRGBEncoding, Texture } from "three";
import { randomRange } from "../Utils";

interface Particle
{
	position: THREE.Vector3;
	velocity: THREE.Vector3;
	cannonVelocity: THREE.Vector3;
	rotation: THREE.Euler;
	rotationVelocity: THREE.Euler;
	randomized: boolean;
	lifetime: number;
	maxLifetime: number;
}

const MAX_PARTICLES: number = 250;

function easeOutExpo(x: number): number
{
	return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

export class ConfettiCannon
{
	private particles: Particle[] = [];
	private particleObjects: THREE.Mesh[] = [];

	private angleDir: THREE.Vector3;
	private cannonPower: number;
	private cannonPowerDuration: number = 1;

	constructor(group: THREE.Group, angleDir: THREE.Vector3, cannonPower: number)
	{
		this.angleDir = angleDir;
		this.cannonPower = cannonPower;

		// tex.encoding = THREE.sRGBEncoding;
		var materialA = new THREE.MeshBasicMaterial({
			color: 0x0090E3,
			side: THREE.DoubleSide,
		})

		var materialB = new THREE.MeshBasicMaterial({
			color: 0xFF6600,
			side: THREE.DoubleSide,
		})

		var texLoader = new THREE.TextureLoader();
		texLoader.load("textures/blue.png", (tex) =>
		{
			materialA.map = tex;
			materialA.map.encoding = sRGBEncoding;
			materialA.needsUpdate = true;
		});

		texLoader.load("textures/orange.png", (tex) =>
		{
			materialB.map = tex;
			materialB.map.encoding = sRGBEncoding;
			materialB.needsUpdate = true;
		});

		var geom = new THREE.PlaneGeometry(0.02, 0.04, 1, 1);

		// var blueMaterial = new THREE.MeshBasicMaterial({
		// 	color: 0x0000ff,
		// 	side: THREE.DoubleSide
		// })

		for (let i = 0; i < MAX_PARTICLES; i++)
		{
			var dir = new THREE.Vector3().copy(this.angleDir);
			dir.applyEuler(new THREE.Euler(
				randomRange(-0.05, 0.05),
				randomRange(-0.05, 0.05),
				randomRange(-0.05, 0.05)
			));
			var particle = {
				position: new THREE.Vector3(
					0, 0, 0
				),
				velocity: new THREE.Vector3(
					randomRange(-0.1, 0.1),
					randomRange(-0.4, -0.9),
					randomRange(-0.1, 0.1),
				),
				cannonVelocity: new THREE.Vector3(
					dir.x + this.cannonPower,
					dir.y + this.cannonPower,
					dir.z + this.cannonPower,
				),
				rotation: new THREE.Euler(
					randomRange(0, 360), randomRange(0, 360), randomRange(0, 360)
				),
				rotationVelocity: new THREE.Euler(
					randomRange(-1, 1),
					randomRange(-1, -5),
					randomRange(-1, 1)
				),
				randomized: false,
				lifetime: 0,
				maxLifetime: randomRange(40, 50)
			}
			this.particles.push(particle);
			var material = materialA;
			if (i / MAX_PARTICLES <= 0.5)
				material = materialA;
			else if (i / MAX_PARTICLES > 0.5)
				material = materialB;
			// else
			// 	material = blueMaterial;
			var mesh = new THREE.Mesh(geom, material);
			this.particleObjects.push(mesh);
			group.add(mesh);
			this.particleObjects[i].position.copy(this.particles[i].position);
		}
	}

	public reset(): void
	{
		for (let i = 0; i < MAX_PARTICLES; i++)
		{
			this.randomize(i);
		}
	}

	public update(dt: number): void
	{
		for (let i = 0; i < this.particles.length; i++)
		{
			this.particles[i].lifetime += dt;
			// if (this.particles[i].lifetime > this.particles[i].maxLifetime)
			// 	this.randomize(i);
			// if (this.particles[i].position.y > 0)
			// 	this.randomize(i);
			// if (Math.abs(this.particles[i].position.x) > this.xRange - 0.01)
			// 	this.randomize(i);

			var vel = new THREE.Vector3();//this.particles[i].velocity;

			// vel = vel.copy(this.particles[i].velocity);
			vel = vel.copy(this.particles[i].cannonVelocity);
			var t = this.particles[i].lifetime / (this.particles[i].maxLifetime * 0.05);
			if (t > 1)
				t = 1;
			t = easeOutExpo(t);
			vel = vel.lerp(this.particles[i].velocity, t);
			// if (this.particles[i].randomized)
			// {
			// var dot = this.particleObjects[i].up.dot(new THREE.Vector3(0, 1, 0));
			// var relativeLife = (1 - (this.particles[i].lifetime / this.particles[i].maxLifetime));
			// var up = this.particleObjects[i].up.multiplyScalar(dot * 0.25);
			// up.x *= relativeLife;
			// up.y *= relativeLife;
			// up.z *= relativeLife;
			// // up.x *= dt;
			// // up.y *= dt;
			// // up.z *= dt;
			// vel.add(up);
			// }
			vel.x *= dt;
			vel.y *= dt;
			vel.z *= dt;

			this.particles[i].position.add(vel);

			this.particleObjects[i].position.copy(this.particles[i].position);

			var rotVel = new THREE.Euler().copy(this.particles[i].rotationVelocity);
			rotVel.x *= dt;
			rotVel.y *= dt;
			rotVel.z *= dt;

			this.particles[i].rotation.x += rotVel.x;
			this.particles[i].rotation.y += rotVel.y;
			this.particles[i].rotation.z += rotVel.z;

			this.particleObjects[i].rotation.copy(this.particles[i].rotation);

		}
	}

	private randomize(index: number): void
	{
		var dir = new THREE.Vector3().copy(this.angleDir);
		dir.applyEuler(new THREE.Euler(
			randomRange(-0.05, 0.05),
			randomRange(-0.05, 0.05),
			randomRange(-0.05, 0.05)
		));
		var particle = {
			position: new THREE.Vector3(
				0, 0, 0
			),
			velocity: new THREE.Vector3(
				randomRange(-0.1, 0.1),
				randomRange(-0.4, -0.9),
				randomRange(-0.1, 0.1),
			),
			cannonVelocity: new THREE.Vector3(
				dir.x + this.cannonPower,
				dir.y + this.cannonPower,
				dir.z + this.cannonPower,
			),
			rotation: new THREE.Euler(
				randomRange(0, 360), randomRange(0, 360), randomRange(0, 360)
			),
			rotationVelocity: new THREE.Euler(
				randomRange(-1, 1),
				randomRange(-1, -5),
				randomRange(-1, 1)
			),
			randomized: false,
			lifetime: 0,
			maxLifetime: randomRange(40, 50)
		}
		this.particles[index] = particle;

		// const particleObj = this.particleObjects[index];
		// var tween = TweenRex({
		// 	duration: randomRange(330, 660),
		// 	subscribe(offset)
		// 	{
		// 		var o = offset + 0.0001;
		// 		particleObj.scale.set(o, o, o);
		// 	}
		// });
		// addToTweens(tween);
		// tweens.push(tween);
		// tween.play();
	}
}