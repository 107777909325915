import { WindowAccess } from "./Decorators";

@WindowAccess()
export class PortraitChecker
{
	private static isLandscapeActive: boolean = false;

	private static landscapeElement: Element;

	private static isChecking: boolean;

	private static interval: NodeJS.Timer;

	public static isLandscape(): boolean
	{
		return this.isLandscapeActive;
	}

	private static isPortrait(): boolean
	{
		return window.matchMedia("(orientation: portrait)").matches;
	}

	public static setEnabled(enabled: boolean) : void
	{
		if (enabled === false)
		{
			this.isChecking = false;
			clearInterval(this.interval);
			this.isLandscapeActive = false;
			this.landscapeElement.classList.add("hidden");
		}
		else
		{
			if (this.isChecking === true)
				return;
			this.check();
		}

		console.log("portrait checker: " + enabled);
	}

	public static check(): void
	{
		var element = document.getElementById("landscape-warning");
		if (element === null)
			return;
		this.landscapeElement = element;
		this.isChecking = true;

		this.interval = setInterval(() =>
		{
			if (this.isChecking === false)
				return;
			if (this.isPortrait() == false)
			{
				if (this.isLandscapeActive === false)
				{
					this.isLandscapeActive = true;
					this.landscapeElement.classList.remove("hidden");
					console.log("fucker");
				}
			}
			else
			{
				if (this.isLandscapeActive === true)
				{
					this.isLandscapeActive = false;
					this.landscapeElement.classList.add("hidden");
				}
			}
	
		}, 333);
	}
}

PortraitChecker.check();