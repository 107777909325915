import { fromEvent, Subscription } from "rxjs";
import { xrService } from "../../main";
import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, Enterable, Exitable, Initializable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { Sfx } from "../Sfx";

const BACK_STATE: string = "tutorial";

@InAppState("scanning")
export class BackButton extends Behaviour implements Initializable, Enterable, Exitable
{
    private modelUI: HTMLElement;
    private button: HTMLElement;
    private onclick: Subscription;

    public initialize(): void 
    {
        this.modelUI = document.getElementById("model-ui")!;
        this.button = document.getElementById("back-button")!;
    }

    public enter(): void 
    {
        this.onclick = fromEvent(this.button, "click").subscribe(() => 
        {
			Sfx.play("click.wav");
			xrService.pause();
            AppStateMachine.setCurrentState(BACK_STATE);
        });
    }

    public exit(): void 
    {
        this.modelUI.style.display = "none";
        this.onclick.unsubscribe();
    }
}