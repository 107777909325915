const timers: Timer[] = [];

export function updateTimers(dt: number): void
{
	for (let i = 0; i < timers.length; i++) {
		timers[i].step(dt);
	}
}

export function wait(ms: number): Promise<void>
{
	var timer = new Timer();
	return new Promise(res =>
	{
		timer.create(res, ms);
	});
}

export class Timer
{
	private remaining: number = 0;

	private callback: () => void = () => { };
	private updateCallback: (seconds: number) => void = () => { };

	// private active: boolean = false;

	private time = 0;

	public create(cb: () => void, delay: number, uCB: (ms: number) => void = () => { })
	{
		this.callback = () =>
		{
			if (cb != null)
				cb();
			timers.splice(timers.indexOf(this), 1);
		};

		if (uCB != null)
			this.updateCallback = uCB;

		this.remaining = delay;
		timers.push(this);
	}

	public step(dt: number)
	{
		this.time += dt;

		if (this.time <= this.remaining)
		{
			if (this.updateCallback != null)
				this.updateCallback(this.time);
			return;
		}
		else
		{
			this.callback();
			return;
		}
	}

	// public resume()
	// {
	// 	this.active = true;
	// }

	// public pause()
	// {
	// 	this.active = false;
	// }
}

export function remap(value: number, inMin: number, inMax: number, outMin: number, outMax: number): number
{
	return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
}

export function randomRange(min: number, max: number)
{
	return remap(Math.random(), 0, 1, min, max);
}