import { Subscription, take } from "rxjs";
import { xrService } from "../../main";
import { AnimationsPlayer } from "../AnimationsPlayer";
import { AppConfig } from "../AppConfig";
import { Behaviour, Enterable, Exitable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { Sfx } from "../Sfx";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { Timeline } from "../Timeline";
import { XR8ImageTrack } from "../XR8Interfaces";
import { VolumetricVideo } from "./VolumetricVideo";

@InAppState("scanning")
export class SystemsController extends Behaviour implements Enterable, Updatable, Exitable
{
	private imageFound: boolean;

	private onTargetFoundSub: Subscription;
	private onTargetUpdatedSub: Subscription;

	public enter(): void
	{
		this.reset();

		if (ThreeJsRoot.allImageTargetsFound() === false)
		{
			this.onTargetFoundSub = xrService.imageFoundObservable().subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
			this.onTargetUpdatedSub = xrService.imageUpdatedObservable().pipe(take(1)).subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
		}
		else
		{
			this.imageFound = true;
		}
	}

	private onImageTarget(detail: XR8ImageTrack): void
	{
		if (ThreeJsRoot.allImageTargetsFound())//(detail.name === AppConfig.get().rootTarget) 
		{
			this.imageFound = true;
			this.onTargetFoundSub?.unsubscribe();
			this.onTargetUpdatedSub?.unsubscribe();
		}
	}

	public update(dt: number): void
	{
		if (this.imageFound === false || VolumetricVideo.checkBufferReady() === false)
			return;
		AnimationsPlayer.update(dt);
		Timeline.update(dt);
	}

	public exit(): void
	{
		this.reset();
	}

	private reset(): void
	{
		this.onTargetFoundSub?.unsubscribe();
		this.onTargetUpdatedSub?.unsubscribe();
		Sfx.reset();
		AnimationsPlayer.reset();
		Timeline.reset();
		this.imageFound = false;
	}
}