import { fromEvent, Subscription } from "rxjs";
import { Behaviour, Enterable, Exitable, Initializable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";

@InAppState("permissions")
export class PermissionsUI extends Behaviour implements Initializable, Enterable, Exitable
{
    // private uiElement: HTMLElement;

    // private reload: HTMLElement;
    // private subscription: Subscription;

    public initialize(): void 
    {
       // this.uiElement = document.getElementById("permissions")!;
        //this.uiElement.style.display = "none";

        //this.reload = document.getElementById("reload-button")!;
    }
    
    public enter(): void 
    {
        // this.uiElement.style.display = "";

        // this.subscription = fromEvent(this.reload, "click").subscribe(() => 
        // {
        //     window.location.reload();
        // });
    }

    public exit(): void 
    {
        // this.uiElement.style.display = "none";
        // this.subscription.unsubscribe();
    }
}