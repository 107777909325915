import { fromEvent, Subscription } from "rxjs";
import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, Enterable, Exitable, Initializable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { Sfx } from "../Sfx";

const BACK_STATE: string = "language-select"

@InAppState("tutorial")
export class TutorialBackButton extends Behaviour implements Enterable, Exitable, Initializable
{
    private button: HTMLElement;
    private onclick: Subscription;

    public initialize(): void 
    {
        this.button = document.getElementById("back-to-ls")!;
    }

    public enter(): void 
    {
        this.onclick = fromEvent(this.button, "click").subscribe(() => 
        {
			Sfx.play("click.wav");
            AppStateMachine.setCurrentState(BACK_STATE);
        });
    }

    public exit(): void 
    {
        this.onclick.unsubscribe();
    }
}