import * as THREE from "three";
import { Behaviour, Initializable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { ThreeJsModelLoader } from "../ThreeJsModelLoader";
import { ThreeJsRoot } from "../ThreeJsRoot";

@InAppState("scanning")
export class ChristmasTreeContour extends Behaviour implements Initializable
{
	initialize(): void
	{
		ThreeJsModelLoader.loadFromUrl("models/contouren/contour-kerstboom-cadeausv2.json").then(obj =>
		{
			ThreeJsRoot.getTargetRoot("test-2").add(obj);
			obj.position.set(0, -0.5, -0.25);
			obj.scale.set(25, 25, 25);//110);
			obj.traverse(child =>
			{
				if (child.material !== undefined)
				{
					child.material.dispose();
					child.renderOrder = -1;
					console.log(child);
					child.material = new THREE.MeshBasicMaterial({
						color: 0xff0000,
						colorWrite: false
					});
				}
			});
		});
	}
}