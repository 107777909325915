strings:
  intro-title: "<span style=\"font-weight: bold\">KERSTDORP<span style=\"color: #FF6600\">.</span></span>"
  intro-subtitle: "Oh scanneboom."
  intro-body: "<span style=\"font-weight: bold\">Welkom</span> in het Coolblue Kerstdorp! Zet je geluid aan, stop heel even met kerstliedjes zingen en ga goedgemutst op pad."
  intro-buttontext: "<span style=\"font-weight: bold\">Ik wil sneeuw op mijn scherm</span>"
  camera-denied-title: "<span style=\"font-weight: bold\">OEPS<span style=\"color: #FF6600\">.</span></span>"
  camera-denied-subtitle: "Kerst-mis."
  camera-denied-body: "Ho ho ho, je moet de Coolblue App nog even toegang geven tot je camera. Dat doe je zo:"
  camera-denied-list1: "Ga naar <span style=\"font-weight: bold\">instellingen</span>"
  camera-denied-list2: "Druk op <span style=\"font-weight: bold\">toegang tot camera</span>"
  camera-denied-list3: "Druk op <span style=\"font-weight: bold\">toestaan</span>"
  camera-denied-list4: "Laad de app opnieuw"
  camera-denied-buttontext: "<span style=\"font-weight: bold\">Laad de app opnieuw</span>"
  search-marker-text: "Scan de onderdelen"
  replay: "Opnieuw afspelen"
