import * as THREE from "three";
import { fromEvent, Subscription, take } from "rxjs";
import { lowPower, xrService } from "../../main";
import { Behaviour, EnterableAsync, Exitable, Initializable, Updatable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { wait } from "../Utils";
import { XR8ImageTrack } from "../XR8Interfaces";
import { WEB4DS } from "../web4dv/web4dvImporter";
import { Localization } from "../Localization";
import { Howl } from "howler";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { PauseManager } from "../PauseManager";
import { AppStateMachine } from "../AppStateMachine";
import { AppConfig } from "../AppConfig";

@InAppState("scanning")
export class VolumetricVideo extends Behaviour implements Initializable, EnterableAsync, Exitable, Updatable
{
	private static video: WEB4DS;

	private onTargetFoundSub: Subscription;
	private onTargetUpdatedSub: Subscription;
	private replayButtonSub: Subscription;

	private howl: Howl;

	private static howlDone: boolean;

	private group: THREE.Group;

	private uiElement: HTMLElement;
	// private replayButtonElement: HTMLElement;

	private videoDone: boolean;
	private speechDone: boolean;
	private static foundAll: boolean;

	public static checkBufferReady(): boolean
	{
		if (AppConfig.get().disableVideo)
			return this.foundAll;
		if (this.video === null || this.video === undefined)
			return false;
		return this.video.IsLoaded() && this.video.PreBufferDone() && this.video.getSequenceDecodedFrames() > 12 && this.howlDone;
	}

	public initialize(): void
	{
		VolumetricVideo.foundAll = false;
		PauseManager.pausedObservable().subscribe(paused =>
		{
			if (paused)
			{
				if (VolumetricVideo.video.IsPlaying())
					this.howl.pause();
			}
			else
			{
				if (VolumetricVideo.video.IsPlaying())
					this.playAudio();
			}
		});

		Localization.regionChangedObservable().subscribe(region =>
		{
			if (this.howl !== undefined)
				this.howl.unload();
			// console.log("gaga");
			VolumetricVideo.howlDone = false;
			Localization.getHowl("speech.mp3", false).then(speech =>
			{
				this.howl = speech;
				this.howl.stop();
				this.howl.on('end', () =>
				{
					this.speechDone = true;
				});
				VolumetricVideo.howlDone = true;
				console.log("got speech!");
			});
		});

		var ui = document.getElementById("model-ui");
		if (ui === null)
		{
			return;
		}
		ui.style.display = "none";
	}

	public async enterAsync(): Promise<void>
	{
		// PortraitChecker.setEnabled(false);
		this.videoDone = false;
		// if (this.group === undefined)
		// {
		this.group = new THREE.Group();
		this.group.scale.set(0.33, 0.33, 0.33);
		this.group.frustumCulled = false;

		xrService.getSceneProvider().getSceneBundle().scene.add(this.group);

		this.group.renderOrder = 5;
		if (AppConfig.get().disableVideo === false)
		{
			var path = "volumetricvideo/0011_30_00fps_FILTERED_MOBILE_720.4ds";
			// if (lowPower)
				path = "volumetricvideo/0011_30_00fps_FILTERED_MOBILE_720_lowpower.4ds"
			VolumetricVideo.video = new WEB4DS(path,
				new THREE.Vector3(0, 0, 2.5), new THREE.Euler(0, 0, 0), this.group);
		}

		console.log("starting load");
		if (AppConfig.get().disableVideo === false)
			await VolumetricVideo.video.load(false, false);
		xrService.getSceneProvider().getSceneBundle().scene.remove(this.group);

		this.speechDone = false;

		while (VolumetricVideo.howlDone === false)
		{
			console.log("waiting for speech howl");
			await wait(0.1);
		}

		// if (this.howl === undefined)
		// {
		// 	this.howl = await Localization.getHowl("speech.mp3", false);
		// 	this.howl.on('end', () =>
		// 	{
		// 		this.speechDone = true;
		// 	});
		// }

		VolumetricVideo.howlDone = true;
		this.howl.stop();
		ThreeJsRoot.getRoot().visible = true;

		console.log("volumetric video loaded!");
		// }
		// else
		// {
		// 	await this.video.buffer();
		// }

		await wait(1.0);


		if (ThreeJsRoot.allImageTargetsFound() === false)
		{
			this.onTargetFoundSub = xrService.imageFoundObservable().subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
			this.onTargetUpdatedSub = xrService.imageUpdatedObservable().pipe(take(1)).subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
		}
		else
		{
			this.start();
		}

		var ui = document.getElementById("model-ui");
		if (ui === null)
		{
			return;
		}
		this.uiElement = ui;
		this.uiElement.style.display = "";

		while (VolumetricVideo.checkBufferReady() === false)
			await wait(0.33);

		document.getElementById("camera-loading")?.classList.add("hidden");

		// var replayButton = ui.getElementsByClassName("replay-button")[0] as HTMLElement;

		// if (replayButton === null)
		// 	return;

		// this.replayButtonElement = replayButton;

		// Debug.addButton("replay", () => this.replay());
		// Debug.addButton("stop", () => this.stop());

		// this.replayButtonElement = this.uiElement.getElementsByClassName("replay-button")[0] as HTMLElement;

		// this.replaySectionElement = document.getElementById("replay-section")!;
		// this.replaySectionElement.style.display = "none";
		// this.bottomSectionElement = document.getElementById("bottom-section")!;

	}

	private onImageTarget(detail: XR8ImageTrack): void
	{
		this.start();
	}

	private start(): void
	{
		if (ThreeJsRoot.allImageTargetsFound() && AppConfig.get().disableVideo)
		{
			VolumetricVideo.foundAll = true;
			this.playAudio();
			return;
		}
		// return;
		// console.log("gaga found");
		if (ThreeJsRoot.allImageTargetsFound() && this.videoDone === false)//(detail.name === AppConfig.get().rootTarget) 
		{
			if (VolumetricVideo.video.IsPlaying() == false)
				this.replay();
		}
	}

	public async play(): Promise<void>
	{
		ThreeJsRoot.getTargetRoot("test-3").add(this.group);
		this.group.setRotationFromEuler(new THREE.Euler(0, -1.57079, 0));//rotateY(-1.5707963268);
		VolumetricVideo.video.play();
		this.howl.seek(0);
		this.playAudio();

		console.log(VolumetricVideo.video.Model4D().Mesh());
		console.log(VolumetricVideo.video.Model4D().Surface());
	}

	private playAudio(): void
	{
		if (this.howl.playing())
			return;

		this.howl.play();
		// this.howl.pause();
		// this.howl.play();
	}

	public stop(): void
	{
		ThreeJsRoot.getRoot().remove(this.group);
		VolumetricVideo.video.reset();
		this.howl.stop();
	}

	public async replay(): Promise<void>
	{
		// this.replayButtonElement.classList.add("disable-button");
		console.log("replay!");
		this.stop();
		await wait(0.1);
		this.play();
	}

	public enterAsyncDone(): void
	{

	}

	exit(): void 
	{
		this.uiElement.style.display = "none";
		this.onTargetFoundSub?.unsubscribe();
		this.onTargetUpdatedSub?.unsubscribe();
		this.replayButtonSub?.unsubscribe();
		this.stop();
		// this.video.clear();
		VolumetricVideo.video.destroy();
		// PortraitChecker.setEnabled(true);
	}

	// unload(): void
	// {

	// }

	update(dt: number): void
	{
		if (AppConfig.get().disableVideo || this.videoDone)
			return;
		if (VolumetricVideo.video.IsPlaying() === false || VolumetricVideo.checkBufferReady() === false)
		{
			// console.log("buffering..");
			if (this.howl !== undefined && this.howl.playing())
				this.howl.pause();
			return;
		}

		if (this.howl !== undefined && this.howl.playing() === false && this.speechDone === false)
			this.howl.play();

		var playing = VolumetricVideo.video.update(dt);

		if (playing)
			return;

		if (VolumetricVideo.video.isFinished() === false)
			return;

		console.log("video finished");
		this.stop();
		this.videoDone = true;
		VolumetricVideo.video.destroy();


		// this.replayButtonElement.classList.remove("disable-button");
		// this.replayButtonSub = fromEvent(this.replayButtonElement, "click").pipe(take(1)).subscribe(() =>
		// {
		// 	// this.replay();
		// 	this.replayButtonElement.classList.add("disable-button");
		// });
		wait(1.0).then(() =>
		{
			AppStateMachine.reloadCurrentState();
		});
	}
}