import * as THREE from "three";
import { BoxGeometry, Mesh, MeshBasicMaterial } from "three";
import { AppConfig } from "./AppConfig";
import { Sfx } from "./Sfx";
import { XR8ImageTrack } from "./XR8Interfaces";

export class ThreeJsRoot
{
	static imageTargets: LoadedImageTarget[];

	static rootObject: THREE.Group;

	static initialize(): void
	{
		this.rootObject = new THREE.Group();
		this.rootObject.name = "ThreeJSRoot";

		this.imageTargets = [];

		var defaultImageTargets = AppConfig.get().defaultImageTargets as ImageTarget[];

		for (let i = 0; i < defaultImageTargets.length; i++)
		{
			var group = new THREE.Group();
			group.name = defaultImageTargets[i].name + "_root";

			if (process.env.NODE_ENV === 'development')
			{
				var mesh = new Mesh(
					new BoxGeometry(0.1, 0.1, 0.1),
					new MeshBasicMaterial({ color: 0xff00ff }));
				group.add(mesh);
			}
			
			this.rootObject.add(group);
			// group.renderOrder = 10;

			var loadedImageTarget = new LoadedImageTarget(defaultImageTargets[i], group);

			this.imageTargets.push(loadedImageTarget);
			if (defaultImageTargets[i].hideWhenLost)
				group.visible = false;
		}

		if (defaultImageTargets !== undefined && defaultImageTargets.length > 0 && AppConfig.get().imageFoundSfx !== undefined)
		{
			Sfx.preload(AppConfig.get().imageFoundSfx);
		}

		// if (AppConfig.get().hideWhenRootTargetIsLost)
		// 	this.rootObject.visible = false;
	}

	static getRoot(): THREE.Group
	{
		return this.rootObject;
	}

	static hasTargetRoot(name: string): boolean
	{
		for (let i = 0; i < this.imageTargets.length; i++)
		{
			if (this.imageTargets[i].data.name === name)
				return true;
		}

		return false;
	}

	static getImageTarget(name: string): LoadedImageTarget | null
	{
		for (let i = 0; i < this.imageTargets.length; i++)
		{
			if (this.imageTargets[i].data.name === name)
				return this.imageTargets[i];
		}

		return null;
	}

	static getTargetRoot(name: string | undefined): THREE.Group
	{
		if (name === undefined)
		{
			return this.getRoot();
		}

		for (let i = 0; i < this.imageTargets.length; i++)
		{
			if (this.imageTargets[i].data.name === name)
				return this.imageTargets[i].group;
		}

		return this.getRoot();
	}

	static anyImageTargetFound() : boolean
	{
		for (let i = 0; i < this.imageTargets.length; i++) {
			if (this.imageTargets[i].hasBeenFound())
				return true;
		}
		return false;
	}

	static allImageTargetsFound() : boolean
	{
		return this.foundAmount() === this.imageTargets.length;
	}

	static foundAmount() : number
	{
		var foundAmount: number = 0;
		for (let i = 0; i < this.imageTargets.length; i++) {
			if (this.imageTargets[i].hasBeenFound())
				foundAmount++;
		}
		return foundAmount;
	}

	static found(imageTrack: XR8ImageTrack)
	{
		if (this.hasTargetRoot(imageTrack.name) === false)
			return;

		var target = this.getImageTarget(imageTrack.name);

		if (target === null)
			return;

		if (target.foundAmount === 0 && AppConfig.get().imageFoundSfx !== undefined)
		{
			Sfx.play(AppConfig.get().imageFoundSfx);
		}
		target.foundAmount++;

		if (target.data.visibleWhenFound)
			target.group.visible = true;
	}

	static updated(imageTrack: XR8ImageTrack)
	{
		if (this.hasTargetRoot(imageTrack.name) === false)
			return;

		var target = this.getImageTarget(imageTrack.name);

		if (target === null)
			return;

		if (target.data.setTargetScale)
		{
			target.group.scale.copy(
				new THREE.Vector3(imageTrack.scale, imageTrack.scale, imageTrack.scale));
		}

		target.group.position.copy(imageTrack.position);
		var translation = target.data.translation;
		target.group.translateX(translation.x);
		target.group.translateY(translation.y);
		target.group.translateZ(translation.z);

		var rotation = target.data.rotation;
		var euler = new THREE.Euler(rotation.x, rotation.y, rotation.z);
		var quat = new THREE.Quaternion().setFromEuler(euler);
		var trackQuat = new THREE.Quaternion(imageTrack.rotation.x, imageTrack.rotation.y, imageTrack.rotation.z, imageTrack.rotation.w);
		trackQuat = trackQuat.multiply(quat);
		target.group.setRotationFromQuaternion(trackQuat);
	}

	static lost(imageTrack: XR8ImageTrack)
	{
		if (this.hasTargetRoot(imageTrack.name) === false)
			return;

		var target = this.getImageTarget(imageTrack.name);

		if (target === null)
			return;

		if (target.data.hideWhenLost)
			target.group.visible = false;
	}
}

interface ImageTarget
{
	name: string;
	rotation: { x: number, y: number, z: number };
	translation: { x: number, y: number, z: number };
	visibleWhenFound: boolean;
	hideWhenLost: boolean;
	setTargetScale: boolean;
}

class LoadedImageTarget
{
	data: ImageTarget;
	group: THREE.Group;
	foundAmount: number = 0;

	constructor(data: ImageTarget, group: THREE.Group)
	{
		this.data = data;
		this.group = group;
	}

	public hasBeenFound(): boolean
	{
		return this.foundAmount > 0;
	}
}