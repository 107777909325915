import { Behaviour, Enterable, Exitable, ExitableAsync, Initializable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { wait } from "../Utils";

@InAppState("stateA")
@DoNotLoadBehaviour()
export class AnotherClass extends Behaviour implements Initializable, Enterable, Updatable, Exitable, ExitableAsync
{
	public initialize(): void
	{
		console.log("aaaaa");
	}

	public enter(): void
	{
		console.log("i am another class!");
	}

	public update(dt: number): void {
		// console.log("update " + dt);
	}

	public exit(): void
	{
		console.log("exitingggg");
	}

	public async exitAsync(): Promise<void>
	{
		console.log("exittttt");
		await wait(1);
		console.log("yes!");
	}
}