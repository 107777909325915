strings:
  intro-title: "<span style=\"font-weight: bold\">WEIHNACHTSDORF<span style=\"color: #FF6600\">.</span></span>"
  intro-subtitle: "Plätzchen packen."
  intro-body: "<span style=\"font-weight: bold\">Willkommen</span> im Coolblue-Weihnachtsdorf! Dreh die Lautstärke hoch, höre kurz auf, Weihnachtslieder zu singen und mache es dir schön gemützlich."
  intro-buttontext: "<span style=\"font-weight: bold\">Ich will Schnee auf dem Display</span>"
  camera-denied-title: "<span style=\"font-weight: bold\">HUCH<span style=\"color: #FF6600\">.</span></span>"
  camera-denied-subtitle: "Schöne Bescherung."
  camera-denied-body: "Ho, ho ho! Du musst der Coolblue-App den Zugriff auf deine Kamera erlauben. Das geht so:"
  camera-denied-list1: "Öffne die <span style=\"font-weight: bold\">Einstellungen</span>"
  camera-denied-list2: "Tippe auf <span style=\"font-weight: bold\">Kamerazugriff</span>"
  camera-denied-list3: "Wähle <span style=\"font-weight: bold\">Zulassen</span> aus"
  camera-denied-list4: "Aktualisiere die Ansicht in der App"
  camera-denied-buttontext: "<span style=\"font-weight: bold\">Aktualisiere die Ansicht in der App</span>"
  search-marker-text: "Fange die Elemente ein"
  replay: "Wiederholung"
