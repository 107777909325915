- name: "snowball"
  event: "snowball"
  path: "models/sneeuwbal.json"
  imageTarget: top
  localPosition:
    x: -0.44
    y: -2.2
    z: -0.5
  localScale:
    x: 33
    y: 33
    z: 33
  localRotation:
    x: 0
    y: 0
    z: 0.6
  visibleStartState: false
  timeScale: 0.66
  renderOrder: 30
  hideWhenDone: true
  hideAfter: 8
# - name: "busje"
#   event: "vehicles"
#   path: "models/busje3.json"
#   imageTarget: busje
#   localPosition:
#     x: 1.75
#     y: -0.5
#     z: 1
#   localScale:
#     x: 50
#     y: 50
#     z: 50
#   localRotation:
#     x: 0
#     y: 3.1415926536
#     z: 0
#   visibleStartState: false
#   timeScale: 1
#   hideWhenDone: true
#   hideAfter: 5
- name: "fiets"
  event: "vehicles"
  path: "models/fiets2.json"
  imageTarget: test-3
  localPosition:
    x: 0
    y: 0
    z: 1.5
  localScale:
    x: 0.33
    y: 0.33
    z: 0.33
  localRotation:
    x: 0
    y: 3.1415926536
    z: 0
  visibleStartState: false
  timeScale: 1
  hideWhenDone: true
- name: "windmills"
  event: "windmills"
  path: "models/windmolens.json"
  imageTarget: windmolen
  localPosition:
    x: 0.625      
    y: -1.925
    z: -0.5
  localScale:
    x: 25
    y: 25
    z: 25
  visibleStartState: false
  timeScale: 1
  hideAfter: 2.5