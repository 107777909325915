// **********************************************************
//
// WEB4DV
// THREE.js plug-in for 4Dviews volumetric video sequences
//
// Version: 3.1.0
// Release date: October 2021
//
// Copyright: 4D View Solutions SAS
// Authors: M.Adam & T.Groubet
//
// NOTE:
// ADD: import WEB4DS from 'yourpath/web4dvImporter.js'
// in your main script
// Then create a WEB4DS object with the right parameters
// Call yourObject.load() to start the streaming
// OPTIONS:
// - yourObject.load( bool showPlaceholder, bool playOnload, callback() )
// Then you can call:
// - play/pause
// - mute/unmute
// - destroy
// - get some info like currentFrame or sequenceTotalLength
//
// **********************************************************

import { xrService } from '../../main';
import { wait } from '../Utils';
import { Model4D } from './model4D_Three';
import { ready } from './web4dvResource.js';
import { default as ResourceManagerXHR, Decoder4D } from './web4dvResource.js';
// import {default as Model4D} from './model4D_Three.js'

// 4Dviews variables
const resourceManager = new ResourceManagerXHR()

// MAIN CLASS MANAGING A 4DS
export class WEB4DS
{
	private videoUrl: string;
	private position: THREE.Vector3;
	private rotation: THREE.Euler;
	private group: THREE.Group;

	private model4D: Model4D;
	public Model4D(): Model4D { return this.model4D; }
	private sequenceTotalLength: number;

	private preBufferDone: boolean = false;
	public PreBufferDone(): boolean { return this.preBufferDone; }
	private isLoaded: boolean = false;
	public IsLoaded(): boolean { return this.isLoaded; }
	private isPlaying: boolean = false;
	public IsPlaying(): boolean { return this.isPlaying; }
	private isDecoding: boolean = false;
	private currentMesh: any;
	public currentFrame: number;

	private decodeLoop: NodeJS.Timer;

	constructor(urlD: string, position: THREE.Vector3, rotation: THREE.Euler, group: THREE.Group)
	{
		/** properties, options and status modifiable by user **/

		// properties
		this.videoUrl = urlD;  				// url Desktop format
		this.position = position;		//mesh position in scene
		this.rotation = rotation;

		this.model4D = new Model4D();	//three.js Object represneting the mesh 4d
		this.sequenceTotalLength = 0;	//sequence number of frames

		// Status
		this.isLoaded = false;
		this.isPlaying = false;
		this.isDecoding = false;

		this.group = group;

		this.currentMesh = null;
		this.currentFrame = -1;


		window.addEventListener("unload", () =>
		{
			console.log("bye");
			this.destroy();
		});
	}

	initSequence(nbFrames: any, nbBlocs: any, framerate: any, maxVertices: number, maxTriangles: number, textureEncoding: number, textureSizeX: number, textureSizeY: number, modelPosition: THREE.Vector3, modelRotation: THREE.Euler)
	{
		const vertices = new Float32Array(maxVertices * 3)
		const uvs = new Float32Array(maxVertices * 2)
		const indices = new Uint32Array(maxTriangles * 3)
		const normals = new Float32Array(maxVertices * 3)

		this.model4D.initMesh(vertices, uvs, indices, normals, textureEncoding, textureSizeX, textureSizeY, modelPosition, modelRotation)

		this.group.add(this.model4D.Mesh());
		this.group.add(this.model4D.Surface());
		this.group.add(this.model4D.Light());
	}

	private videoDt: number;

	// methods
	async load(showPlaceholder: boolean, playOnload: boolean): Promise<void>
	{
		this.preBufferDone = false;
		// while (ready === false)
		// {
		// 	console.log("MODULE NOT READY");
		// 	await wait(0.1);
		// }
		return new Promise<void>((resolve, reject) =>
		{
			if (!this.isLoaded)
			{
				resourceManager.set4DSFile(this.videoUrl);
				var extensions = xrService.getSceneProvider().getRendererExtensions();
				var textureEncoding = extensions !== null && extensions.get('WEBGL_compressed_texture_astc') ? 164 : 100;
				Decoder4D.SetInputTextureEncoding(textureEncoding);

				resourceManager.Open(() =>
				{
					const si = resourceManager._sequenceInfo

					this.initSequence(si.NbFrames, si.NbBlocs, si.Framerate, si.MaxVertices, si.MaxTriangles, si.TextureEncoding, si.TextureSizeX, si.TextureSizeY, this.position, this.rotation)  // Get sequence information

					this.decode()  // Start decoding, downloading

					var current: number = -1;

					const waiter = setInterval(() =>
					{
						if (Decoder4D._meshesCache.length >= Decoder4D._maxCacheSize)
						{
							this.preBufferDone = true;
							console.log("buffer done");
							clearInterval(waiter);  // Stop the waiter loop
							this.videoDt = 1 / resourceManager._sequenceInfo.FrameRate;

							var firstMesh: any = Decoder4D._meshesCache[0];
							this.currentMesh = firstMesh;
							this.currentFrame = firstMesh.frame;
							this.updateSequenceMesh(firstMesh);
							resolve();
						}
						if (current != Decoder4D._meshesCache.length)
						{
							console.log("buffered: " + Decoder4D._meshesCache.length);
							current = Decoder4D._meshesCache.length;
						}
					}, 0.1667);

					this.isLoaded = true
					this.sequenceTotalLength = si.NbFrames
				});
			}
			else
				alert('A sequence is already loaded. One sequence at a time.')
		});
	}

	private updateSequenceMesh(mesh: any): void
	{
		// console.log(mesh);
		this.model4D.updateMesh(mesh.vertices, mesh.faces, mesh.uvs, mesh.normals, mesh.texture, mesh.nbVertices, mesh.nbFaces)
	}

	// Decode 4D Sequence
	private decode(): void
	{
		if (this.isDecoding)
			return;

		const dt = 1000.0 / (resourceManager._sequenceInfo.FrameRate * 6)

		this.isDecoding = true

		console.log("start decoding");

		/* Decoding loop, 3*fps, need to run this faster than 0,0166666667 seconds */
		this.decodeLoop = setInterval(() =>
		{
			if (this.isDecoding === false)
				return;
			/* Decode chunk */
			for (let i = 0; i < 6; i++) Decoder4D.DecodeChunk()

			/* If a few chunks, download more */
			const maxCache = resourceManager._sequenceInfo.NbFrames * 2 < 300 ? resourceManager._sequenceInfo.NbFrames * 2 : 300

			if (Decoder4D._chunks4D.length < maxCache || (Decoder4D._keepChunksInCache === true && Decoder4D._chunks4D.length < resourceManager._sequenceInfo.NbFrames * 2))
			{
				resourceManager._internalCacheSize = 6000000  // 6 Mo

				resourceManager.getBunchOfChunks()
			}
		}, dt)
	}

	private stopDecoding(): void
	{
		console.log('Stop decoding')
		clearInterval(this.decodeLoop)
		this.isDecoding = false
	}

	// For now, will pause any WEB4DV object created (function is generic)
	// pause()
	// {
	// 	this.isPlaying = false

	// 	if (Decoder4D._meshesCache >= Decoder4D._maxCacheSize)
	// 	{
	// 		this.stopDecoding()
	// 	}
	// }

	// For now, will play any WEB4DV object created (function is generic)
	public play(): void
	{
		if (this.isPlaying)
		{  // If sequence is already playing, do nothing
			return
		}

		// If not decoding, decode
		this.decode()
		this.isPlaying = true
		console.log("playin");
	}

	private dtCounter: number = 0;

	public update(dt: number): boolean
	{
		if (Decoder4D._meshesCache.length === 0)
		{
			console.warn("buffer empty!");
			return false;
		}
		this.dtCounter += dt;
		if (this.dtCounter < this.videoDt)
			return false;
		while (this.dtCounter >= this.videoDt)
		{
			this.dtCounter -= this.videoDt;
			if (this.advanceFrame(false))
				return false;
		}

		if (this.currentMesh !== undefined && this.currentMesh !== null)
			this.updateSequenceMesh(this.currentMesh);

		return true;
	}

	private advanceFrame(updateMesh: boolean): boolean
	{
		this.currentMesh = Decoder4D._meshesCache.shift();
		if (this.currentMesh === null || this.currentMesh === undefined)
			return false;
		if (this.currentMesh) this.currentFrame = this.currentMesh.frame;
		if (updateMesh)
			this.updateSequenceMesh(this.currentMesh);
		return this.isFinished();
	}

	public keepsChunksInCache(booleanVal: boolean): void
	{
		Decoder4D._keepChunksInCache = booleanVal
	}

	public destroy(): void
	{
		this.stopDecoding()

		resourceManager.reinitResources()

		if (this.isLoaded)
		{
			this.group.remove(this.model4D.Mesh());
			this.group.remove(this.model4D.Surface());
			this.group.remove(this.model4D.Light());
		}

		this.isLoaded = false
		this.isPlaying = false
		this.isDecoding = false

		this.currentMesh = null

		Decoder4D._meshesCache = []
		Decoder4D._chunks4D = []

		// Reset Sequence Infos
		this.currentFrame = 0
		this.sequenceTotalLength = 0

		this.preBufferDone = false;
	}

	public reset(): void
	{
		console.log("reset");
		this.stopDecoding();
		if (this.isPlaying === false)
			return;
		this.isPlaying = false;
		resourceManager.seek(0);
		// this.stopDecoding();
	}

	public getSequenceDecodedFrames(): number
	{
		if (Decoder4D === undefined)
			return -1;
		if (Decoder4D._meshesCache === undefined)
			return -1;
		return Decoder4D._meshesCache.length;
	}

	public isFinished(): boolean
	{
		return this.currentFrame >= this.sequenceTotalLength - 1;
	}
}