import { Subscription } from "rxjs";
import { xrService } from "../../main";
import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, Enterable, EnterableAsync, ExitableAsync } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { wait } from "../Utils";

@InAppState("xrinit")
export class XRInit extends Behaviour implements EnterableAsync, ExitableAsync
{
	private sub: Subscription;

	public async enterAsync(): Promise<void>
	{
		var currentStatus: string = "";
		this.sub = xrService.cameraStatusObservable().subscribe(status =>
			{
				currentStatus = status;
			});
		xrService.run();
		while (xrService.ready() == false && currentStatus != "hasVideo")
			await wait(0.1);
	}

	public enterAsyncDone(): void
	{
		this.sub.unsubscribe();
		AppStateMachine.requestNextState();
	}

	async exitAsync(): Promise<void>
	{
		// await wait(1);
		// document.getElementById("camera-loading")?.classList.add("hidden");
	}
}