import { Behaviour, Enterable, EnterableAsync, Exitable, ExitableAsync, Initializable, Updatable } from "./BehaviourInterfaces";
import { WindowAccess } from "./Decorators";

@WindowAccess()
export class AppStateMachine
{
	static appConfig: any;

	static currentStateName: string = "";

	static statesMap: Map<string, AppState> = new Map<string, AppState>();

	static isSwitchingStates: boolean = false;

	static initialize(config: any): void
	{
		this.appConfig = config;
		this.setCurrentState(this.appConfig.startingState, true);
	}

	static async setCurrentState(stateName: string, force: boolean = false): Promise<void>
	{
		if (this.isSwitchingStates && force === false)
		{
			console.error("[AppStateMachine] am switching state");
			return;
		}

		if (this.statesMap.has(stateName) === false && force === false)
		{
			console.error("[AppStateMachine] state '" + stateName + "' does not exist");
			return;
		}

		this.isSwitchingStates = true;

		// exit old state
		console.log("[AppStateMachine] exiting state '" + this.currentStateName + "'");
		var oldState = this.statesMap.get(this.currentStateName)
		oldState?.exit();
		await oldState?.exitAsync();

		this.currentStateName = stateName;
		console.log("[AppStateMachine] entering state '" + this.currentStateName + "'");

		// enter new state
		var newState = this.statesMap.get(stateName);
		newState?.enter()
		await newState?.enterAsync();

		console.log("[AppStateMachine] done entering state '" + this.currentStateName + "'");

		this.isSwitchingStates = false;

		newState?.enterAsyncDone();
	}

	static getCurrentStateName(): string
	{
		return this.currentStateName;
	}

	static getState(stateName: string): AppState | undefined // TODO: make IState and NullState?
	{
		if (this.statesMap.has(stateName))
			return this.statesMap.get(stateName);

		var state = new AppState();
		this.statesMap.set(stateName, state);

		return state;
	}

	static getAllStateNames(): string[]
	{
		return Array.from(this.statesMap.keys());
	}

	static requestNextState(force: boolean = false): void
	{
		var stateProgressionArray = this.appConfig.stateProgression as string[];
		var currentStateIndex = stateProgressionArray.indexOf(this.currentStateName);
		var nextState = currentStateIndex + 1;
		if (nextState > stateProgressionArray.length - 1)
		{
			console.log("no next state");
			return;
		}

		this.setCurrentState(stateProgressionArray[nextState], force);
	}

	static reloadCurrentState(): void
	{
		this.setCurrentState(this.currentStateName);
	}

	static update(dt: number): void
	{
		this.statesMap.get(this.currentStateName)?.update(dt);
	}

	public static dispose(): void
	{
		this.statesMap.forEach((value: AppState) =>
		{
			value.dispose();
		});
	}

	static isInitializable(object: any): object is Initializable
	{
		return 'initialize' in object;
	}

	static isEnterable(object: any): object is Enterable
	{
		return 'enter' in object;
	}

	static isEnterableAsync(object: any): object is EnterableAsync
	{
		return 'enterAsync' in object;
	}

	static isUpdatable(object: any): object is Updatable
	{
		return 'update' in object;
	}

	static isExitable(object: any): object is Exitable
	{
		return 'exit' in object;
	}

	static isExitableAsync(object: any): object is ExitableAsync
	{
		return 'exitAsync' in object;
	}


}

export class AppState
{
	private enterables: Enterable[] = [];
	private enterablesAsync: EnterableAsync[] = [];
	private updatables: Updatable[] = [];
	private exitables: Exitable[] = [];
	private exitablesAsync: ExitableAsync[] = [];

	public add(behaviour: Behaviour): void
	{
		if (AppStateMachine.isInitializable(behaviour))
			behaviour.initialize();
		if (AppStateMachine.isEnterable(behaviour))
			this.enterables.push(behaviour);
		if (AppStateMachine.isEnterableAsync(behaviour))
			this.enterablesAsync.push(behaviour);
		if (AppStateMachine.isUpdatable(behaviour))
			this.updatables.push(behaviour);
		if (AppStateMachine.isExitable(behaviour))
			this.exitables.push(behaviour);
		if (AppStateMachine.isExitableAsync(behaviour))
			this.exitablesAsync.push(behaviour);
	}

	public enter(): void
	{
		for (let i = 0; i < this.enterables.length; i++)
		{
			this.enterables[i].enter();
		}
	}

	public enterAsync(): Promise<void[]>
	{
		return this.handleAsync(this.enterablesAsync.map(x => x.enterAsync()));
	}

	public enterAsyncDone(): void
	{
		for (let i = 0; i < this.enterablesAsync.length; i++)
		{
			this.enterablesAsync[i].enterAsyncDone();
		}
	}

	public exit(): void
	{
		for (let i = 0; i < this.exitables.length; i++)
		{
			this.exitables[i].exit();
		}
	}

	public exitAsync(): Promise<void[]>
	{
		return this.handleAsync(this.exitablesAsync.map(x => x.exitAsync()));
	}

	private handleAsync(asyncs: Promise<void>[]): Promise<void[]>
	{
		if (asyncs === null || asyncs.length == 0)
		{
			return Promise.resolve<void[]>([]);
		}
		return Promise.all(asyncs);
	}

	public update(dt: number): void
	{
		for (let i = 0; i < this.updatables.length; i++)
		{
			this.updatables[i].update(dt);
		}
	}

	public dispose(): void
	{

	}
}