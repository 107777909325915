// **********************************************************
//
// WEB4DV
// THREE.js plug-in for 4Dviews volumetric video sequences
//
// Version: 3.1.0
// Release date: October 2021
//
// Copyright: 4D View Solutions SAS
// Authors: M.Adam & T.Groubet
//
// NOTE:
// ADD: import WEB4DS from 'yourpath/web4dvImporter.js'
// in your main script
// Then create a WEB4DS object with the right parameters
// Call yourObject.load() to start the streaming
// OPTIONS:
// - yourObject.load( bool showPlaceholder, bool playOnload, callback() )
// Then you can call:
// - play/pause
// - mute/unmute
// - destroy
// - get some info like currentFrame or sequenceTotalLength
//
// **********************************************************

import * as THREE from "three";

export class Model4D
{
	private geometry: THREE.BufferGeometry;
	private material: THREE.MeshBasicMaterial;
	private texture: THREE.CompressedTexture | THREE.DataTexture;
	private mesh: THREE.Mesh;
	public Mesh(): THREE.Mesh { return this.mesh }
	private textureSizeX: number = 0;
	private textureSizeY: number = 0;

	private surface: THREE.Mesh;
	public Surface(): THREE.Mesh { return this.surface }
	private light: THREE.DirectionalLight;
	public Light(): THREE.DirectionalLight { return this.light }

	initMesh(vertices: ArrayLike<number>, uvs: ArrayLike<number>, indices: ArrayLike<number>, normals: ArrayLike<number>, textureEncoding: number, textureSizeX: number, textureSizeY: number, modelPosition: THREE.Vector3, modelRotation: THREE.Euler)
	{
		this.geometry = new THREE.BufferGeometry();
		this.geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
		this.geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
		this.geometry.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
		this.geometry.setIndex(new THREE.BufferAttribute(indices, 1));
		(this.geometry as any).dynamic = true;

		if (textureEncoding === 164)
		{  // astc
			this.texture = new THREE.CompressedTexture([], textureSizeX, textureSizeY,
				THREE.RGBA_ASTC_8x8_Format, THREE.UnsignedByteType, THREE.UVMapping,
				THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
				THREE.LinearFilter, THREE.LinearFilter);
		} else if (textureEncoding === 100)
		{  // dxt
			this.texture = new THREE.CompressedTexture([], textureSizeX, textureSizeY,
				THREE.RGB_S3TC_DXT1_Format, THREE.UnsignedByteType, THREE.UVMapping,
				THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
				THREE.LinearFilter, THREE.LinearFilter);
		} else
		{  // rgba
			this.texture = new THREE.DataTexture(null, textureSizeX, textureSizeY, THREE.RGBAFormat,
				THREE.UnsignedByteType, THREE.UVMapping,
				THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
				THREE.LinearFilter, THREE.LinearFilter);
		}

		this.textureSizeX = textureSizeX;
		this.textureSizeY = textureSizeY;
		// this.material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
		this.material = new THREE.MeshBasicMaterial({ map: this.texture });

		this.mesh = new THREE.Mesh(this.geometry, this.material);
		this.mesh.name = 'volumetric-video-mesh';
		this.mesh.position.copy(modelPosition);
		this.mesh.rotation.copy(modelRotation);
		this.mesh.frustumCulled = false;
		this.mesh.castShadow = true;

		this.surface = new THREE.Mesh(
			new THREE.PlaneGeometry(3, 3, 1, 1),
			// new THREE.MeshBasicMaterial({ color: 0x00ff00 })
			new THREE.ShadowMaterial({
				opacity: 0.7,
			})
		);

		this.surface.rotateX(-Math.PI / 2);
		this.surface.position.set(modelPosition.x, modelPosition.y, modelPosition.z);
		this.surface.receiveShadow = true;
		this.surface.frustumCulled = false;

		this.light = new THREE.DirectionalLight(0xffffff, 5);
		this.light.position.set(modelPosition.x, modelPosition.y + 10, modelPosition.z);
		this.light.target = this.surface;
		this.light.frustumCulled = false;;

		this.light.castShadow = true;
		this.light.shadow.mapSize.width = 512;
		this.light.shadow.mapSize.height = 512;

		const d = 4;
		this.light.shadow.camera.left = -d;
		this.light.shadow.camera.right = d;
		this.light.shadow.camera.top = d;
		this.light.shadow.camera.bottom = -d;
		this.light.shadow.camera.far = 20
	}

	updateMesh(Verts: ArrayLike<number>, Faces: ArrayLike<number>, UVs: ArrayLike<number>, Normals: ArrayLike<number>, Texture: THREE.CompressedTexture | THREE.DataTexture, nbVerts: number, nbFaces: number)
	{
		/* update the buffers */
		// this.geometry.setAttribute("position", new THREE.BufferAttribute(Verts, 3));
		this.mesh.geometry.attributes.position.needsUpdate = true;
		this.mesh.geometry.attributes.position.array = Verts;

		/* flags */
		this.mesh.geometry.attributes.position.needsUpdate = true;

		if (this.mesh.geometry.drawRange.count !== nbFaces * 3)
		{
			if (this.mesh != null && this.mesh.geometry != null && this.mesh.geometry.index != null)
			{
				this.mesh.geometry.index.array = Faces;
			}

			//   this.geometry.setAttribute("uv", new THREE.BufferAttribute(UVs, 2));
			this.mesh.geometry.attributes.uv.array = UVs;
			this.mesh.geometry.attributes.uv.needsUpdate = true;

			if (this.mesh != null && this.mesh.geometry != null && this.mesh.geometry.index != null)
			{
				this.mesh.geometry.index.needsUpdate = true;
			}

			//   this.geometry.setAttribute("normal", new THREE.BufferAttribute(Normals, 3));
			this.mesh.geometry.attributes.normal.array = Normals;
			this.mesh.geometry.attributes.normal.needsUpdate = true;

			/* to use only part of the buffer */
			this.mesh.geometry.setDrawRange(0, nbFaces * 3);
		}

		this.mesh.rotation.x = -1.57;

		/* update the texture */
		this.texture.mipmaps = [{ 'data': Texture, 'width': this.textureSizeX, 'height': this.textureSizeY }];
		this.texture.needsUpdate = true;
	}

	setPosition(modelPositionVec3: THREE.Vector3)
	{
		this.mesh.position.copy(modelPositionVec3);

		this.surface.position.set(modelPositionVec3.x, modelPositionVec3.y, modelPositionVec3.z);
		this.light.position.set(modelPositionVec3.x, modelPositionVec3.y + 5, modelPositionVec3.z);
	}

	setRotation(modelOrientationVec3: THREE.Euler)
	{
		this.mesh.setRotationFromEuler(modelOrientationVec3);
	}
}