import { XR8DeviceEstimation } from "./XR8Interfaces";

declare const XR8: any;

export class Device
{
	static isCompatible(): boolean
	{
		var comp = XR8.XrDevice.isDeviceBrowserCompatible();

		return comp;
	}

	static estimation(): XR8DeviceEstimation
	{
		var estimation = XR8.XrDevice.deviceEstimate() as XR8DeviceEstimation;

		if (process.env.NODE_ENV === 'development')
		{
			console.log(`[Device] estimation: locale: ${estimation.locale}, os: ${estimation.os}, osVersion: ${estimation.osVersion}, manufacturer: ${estimation.manufacturer}, model: ${estimation.model}`);
		}

		return estimation;
	}
}