import * as THREE from "three";
import { wait } from "./Utils";

export class ThreeJsModelLoader
{
	static map: Map<string, ModelLoad> = new Map<string, ModelLoad>();

	public static IsLoading(): boolean
	{
		this.map.forEach((value: ModelLoad, key: string) =>
		{
			if (value.isModelLoaded() === false)
				return true;
		});

		return false;
	}

	static loadFromUrl(url: string): Promise<THREE.Object3D>
	{
		if (this.map.has(url))
		{
			var modelLoad = this.map.get(url);
			if (modelLoad != undefined)
				return modelLoad.getObject();
		}

		this.map.delete(url);
		var newModelLoad = new ModelLoad(url);
		this.map.set(url, newModelLoad);
		return newModelLoad.load();
	}

	static async waitForLoad(url: string): Promise<void>
	{
		var has = this.map.has(url);
		while (has === false)
		{
			await wait(0.1);
			has = this.map.has(url);
		}
		var modelLoad = this.map.get(url);
		while (modelLoad?.isModelLoaded() == false)
		{
			await wait(0.1);
		}
	}
}

export class ModelLoad
{
	private url: string;
	private object: THREE.Object3D;
	private isLoaded: boolean = false;
	private startedLoading: boolean = false;

	constructor(url: string) 
	{
		this.url = url;
	}

	public async load(): Promise<THREE.Object3D>
	{
		if (this.startedLoading)
		{
			return this.getObject();
		}

		this.startedLoading = true;
		const loader = new THREE.ObjectLoader();
		const url = this.url;
		console.log("loading " + url);
		return new Promise<THREE.Object3D>((resolve, reject) =>
		{
			loader.load(url,
				(obj) =>
				{
					this.isLoaded = true;
					console.log("loaded " + url);
					resolve(obj);
				},
				// onProgress callback
				function (xhr)
				{
					// console.log((xhr.loaded / xhr.total * 100) + '% loaded ' + url);
				},
				// onError callback
				function (err)
				{
					console.error('An error happened');
					console.error(err);
					reject();
				});
		});
	}

	public isModelLoaded(): boolean
	{
		return this.isLoaded;
	}

	public async getObject(): Promise<THREE.Object3D>
	{
		if (this.isLoaded && this.object != null)
			return Promise.resolve(this.object);

		while (this.isLoaded == false)
			await wait(0.1);

		return Promise.resolve(this.object);
	}
}