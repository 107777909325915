import { Subscription } from "rxjs";
import { Behaviour, Enterable, Exitable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { Timeline } from "../Timeline";

@InAppState("stateA")
@DoNotLoadBehaviour()
export class TimelineExample extends Behaviour implements Enterable, Updatable, Exitable
{
	private eventSub: Subscription;

	enter(): void
	{
		console.log("abab");
		Timeline.reset();
		this.eventSub = Timeline.EventObservable().subscribe(event =>
		{
			console.log("!!! " + event);
		});
	}

	update(dt: number): void
	{
		Timeline.update(dt);
	}

	exit(): void
	{
		this.eventSub?.unsubscribe();
	}
}