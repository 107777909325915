import { Behaviour, Enterable, Initializable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { Timeline } from "../Timeline";

@InAppState("scanning")
@DoNotLoadBehaviour()
export class WindmillAnimEvent extends Behaviour implements Initializable, Enterable
{
    private windmill: THREE.Object3D; 

    public initialize(): void 
    {
        Timeline.EventObservable().subscribe(async (event) => 
            {
                if (event == "windmills") 
                {
                    await this.delay(2500);

                    this.windmill.visible = false;
                }
            });
    }

    public enter(): void
    {
        this.windmill = ThreeJsRoot.getTargetRoot("windmolen").getObjectByName("windmills")!;
    }
 
    private delay(ms: number): Promise<unknown>
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}