export class AppConfig
{
	private static config: any;

	public static initialize(configFile: any) : void
	{
		this.config = configFile;
		console.log(this.config);
	}

	public static get(): any
	{
		return this.config;
	}
}