enableLocalization: true
defaultRegion: null
startingState: opening
stateProgression:
  - opening
  - language-select
  - tutorial
  - xrinit
  - scanning
useImageTracking: true
imageFoundSfx: "CoolbluePling.mp3"
defaultImageTargets:
  - name: "busje"
    rotation:
      x: 0
      y: 0
      z: 0
    translation:
      x: 0
      y: 0
      z: 0
    visibleWhenFound: true
    hideWhenLost: false
    setTargetScale: true
  - name: "windmolen"
    rotation:
      x: 0
      y: 0
      z: 0
    translation:
      x: 0
      y: 0
      z: 0
    visibleWhenFound: true
    hideWhenLost: false
    setTargetScale: true
  - name: "test-2"
    rotation:
      x: 0
      y: 0
      z: 0
    translation:
      x: 0
      y: 0
      z: 0
    visibleWhenFound: true
    hideWhenLost: false
    setTargetScale: true
  - name: "top"
    rotation:
      x: 0
      y: 0
      z: 0
    translation:
      x: 0
      y: 0
      z: 0
    visibleWhenFound: true
    hideWhenLost: false
    setTargetScale: true
  - name: "test-3"
    rotation:
      x: 0
      y: 0
      z: 0
    translation:
      x: 0
      y: -0.66
      z: -0.125
    visibleWhenFound: true
    hideWhenLost: false
    setTargetScale: true
# rootTarget: test-3
# rootRotation:
#   x: 0
#   y: -0.1745329252
#   z: 0
# rootOffset:
#   x: -1.158
#   y: -0.428
#   z: -0.307999
# showWhenRootTargetIsFound: true
# hideWhenRootTargetIsLost: false
# setRootTargetScale: true
startXRElementIDs:
  - start-button
disableVideo: false
# - dutch
# - english
# - german
# - french