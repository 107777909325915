import * as THREE from "three";
import { AdditiveBlending, MultiplyBlending } from "three";
import { xrService } from "../../main";
import { Behaviour, Enterable, Initializable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { ThreeJsRoot } from "../ThreeJsRoot";

@InAppState("scanning") 
@DoNotLoadBehaviour()
export class AddCube extends Behaviour implements Initializable, Enterable, Updatable
{
	private cube: THREE.Mesh;

	initialize(): void
	{
		const geometry = new THREE.BoxGeometry(2, 5, 2);
		const material = new THREE.MeshBasicMaterial({
			color: 0xff0000,
			// opacity: 0.5,
			// transparent: true,
			//blending: AdditiveBlending,
			colorWrite: false
		});
		this.cube = new THREE.Mesh(geometry, material);
		// this.cube.position.z = -5;
		ThreeJsRoot.getRoot().add(this.cube);
		this.cube.position.y = -2.5;
	}

	enter(): void
	{
		// xrService.run();
	}

	update(dt: number): void
	{
		// this.cube.rotateX(dt);
		// this.cube.rotateY(dt * 2);
		//this.cube.rotateZ(dt * 3);
	}
}