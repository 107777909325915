strings:
  intro-title: "<span style=\"font-weight: bold\">VILLAGE DE NOËL<span style=\"color: #FF6600\">.</span></span>"
  intro-subtitle: "De la magie dans l'air."
  intro-body: "<span style=\"font-weight: bold\">Bienvenue</span> dans le village de Noël de Coolblue ! Montez le son, arrêtez de chanter les chants de Noël pendant une minute et couvrez-vous bien avant de sortir."
  intro-buttontext: "<span style=\"font-weight: bold\">Que la neige soit.</span>"
  camera-denied-title: "<span style=\"font-weight: bold\">OUPS<span style=\"color: #FF6600\">.</span></span>"
  camera-denied-subtitle: "Ça sent le sapin."
  camera-denied-body: "Ho ho ho, vous devez encore autoriser l’appli Coolblue à avoir accès à votre appareil photo. Voici comment ça marche :"
  camera-denied-list1: "Allez dans <span style=\"font-weight: bold\">réglages</span>"
  camera-denied-list2: "Appuyez sur <span style=\"font-weight: bold\">accès à l’appareil photo</span>"
  camera-denied-list3: "Appuyez sur <span style=\"font-weight: bold\">autoriser</span>"
  camera-denied-list4: "Rechargez l’appli"
  camera-denied-buttontext: "<span style=\"font-weight: bold\">Rechargez l’appli</span>"
  search-marker-text: "Scanner les parties"
  replay: "Rejouer"
