import * as THREE from "three";
import { Behaviour, Enterable, Exitable, Initializable, Updatable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { ThreeJsModelLoader } from "../ThreeJsModelLoader";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { Timeline } from "../Timeline";

const OBJECT_PATH_TOPPER: string = "models/Piek.json";
const OBJECT_PATH_ORNAMENTS: string = "models/Kerstballen.json";
const OBJECT_PATH_LIGHTS: string = "models/Kerstverlichting.json";

const TIMELINE_NAME_TOPPER: string = "piek"
const TIMELINE_NAME_LIGHTS: string = "lights"

const NAME_BAUBLE_BLUE: string = "Blauwe Kerstbal";
const NAME_BAUBLE_RED: string = "Rode Kerstbal";
const NAME_BAUBLE_YELLOW: string = "Gele Kerstbal";
const NAME_BAUBLE_WHITE: string = "Witte Kerstbal";

const OPACITY_LIGHTS_ON: number = 1;
const OPACITY_LIGHTS_OFF: number = 0.33;

const OFFSET_POS_TREE: THREE.Vector3 = new THREE.Vector3(-0.01, -0.6, -0.25);
const SCALE_TREE: THREE.Vector3 = new THREE.Vector3(0.275, 0.275, 0.275);

const TARGET_TREE: string = "test-2";

@InAppState("scanning")
export class ChristmasLights extends Behaviour implements Initializable, Enterable, Updatable, Exitable
{
	private objTopper: THREE.Object3D;
	private objOrnaments: THREE.Object3D;
	private objLights: THREE.Object3D;
	private objHouse: THREE.Object3D;

	private matTreeTopper: THREE.Material;
	private matTreeLights: THREE.Material;

	private matOrnamentsBlue: THREE.Material;
	private matOrnamentsRed: THREE.Material;
	private matOrnamentsYellow: THREE.Material;
	private matOrnamentsWhite: THREE.Material;

	private ornamentsActive: boolean;
	private animStarted: boolean;
	private curTime: number;

	public initialize(): void 
	{
		this.animStarted = false;
		this.curTime = 0;

		ThreeJsModelLoader.loadFromUrl(OBJECT_PATH_ORNAMENTS)
			.then((obj) =>
			{
				this.objOrnaments = obj;
				this.objOrnaments.visible = false;
				this.matOrnamentsBlue = obj.getObjectByName(NAME_BAUBLE_BLUE)!.material;
				this.matOrnamentsRed = obj.getObjectByName(NAME_BAUBLE_RED)!.material;
				this.matOrnamentsYellow = obj.getObjectByName(NAME_BAUBLE_YELLOW)!.material;
				this.matOrnamentsWhite = obj.getObjectByName(NAME_BAUBLE_WHITE)!.material;

				this.setOrnamentLightsActive(false);

				ThreeJsRoot.getTargetRoot(TARGET_TREE).add(this.objOrnaments);

				this.objOrnaments.scale.copy(SCALE_TREE);
				this.objOrnaments.position.copy(OFFSET_POS_TREE);
			});

		ThreeJsModelLoader.loadFromUrl(OBJECT_PATH_LIGHTS)
			.then((obj) => 
			{
				this.objLights = obj;		
				this.objLights.visible = false;
				this.matTreeLights = obj.children[0].material;

				this.setTreeLightsActive(false);

				ThreeJsRoot.getTargetRoot(TARGET_TREE).add(this.objLights);

				this.objLights.scale.copy(SCALE_TREE);
				this.objLights.position.copy(OFFSET_POS_TREE);
			});

		ThreeJsModelLoader.loadFromUrl(OBJECT_PATH_TOPPER)
			.then((obj) => 
			{
				this.objTopper = obj;	
				this.objTopper.visible = false;
				this.matTreeTopper = obj.children[0].material;	

				this.setTopperLightActive(false);

				ThreeJsRoot.getTargetRoot(TARGET_TREE).add(this.objTopper);

				this.objTopper.scale.copy(SCALE_TREE);
				this.objTopper.position.copy(OFFSET_POS_TREE);
			});

		Timeline.EventObservable().subscribe(event => 
			{
				if (event == TIMELINE_NAME_TOPPER)
				{
					this.objTopper.visible = true;
					this.setTopperLightActive(true);
				}
				else if (event == TIMELINE_NAME_LIGHTS)
				{
					this.animStarted = true;
					this.ornamentsActive = true;
					this.objOrnaments.visible = true;
					this.objLights.visible = true;
					this.setTopperLightActive(false);
					this.setOrnamentLightsActive(true);
					this.setTreeLightsActive(true);
				}
			});
	}

	public enter(): void
	{
		this.setOrnamentLightsActive(false);
		this.setTreeLightsActive(false);
		this.setTopperLightActive(false);
	}

	public update(dt: number): void 
	{
		if (this.animStarted) 
		{
			this.curTime += dt;

			if (this.curTime > 1) 
			{
				this.ornamentsActive = !this.ornamentsActive;
				this.setOrnamentLightsActive(this.ornamentsActive);
				this.setTopperLightActive(!this.ornamentsActive);
				this.setTreeLightsActive(!this.ornamentsActive);
				this.curTime = 0;
			}
		}
	}

	public exit(): void 
	{
		this.objTopper.visible = false;
		this.objOrnaments.visible = false;
		this.objLights.visible = false;
		this.setTopperLightActive(false);
		this.setOrnamentLightsActive(false);
		this.setTreeLightsActive(false);
	}

	public setTopperLightActive(active: boolean) 
	{
		this.matTreeTopper.opacity = active ? OPACITY_LIGHTS_ON : OPACITY_LIGHTS_OFF;
	}

	public setOrnamentLightsActive(active: boolean) 
	{
		const opacity: number = active ? OPACITY_LIGHTS_ON : OPACITY_LIGHTS_OFF;
		this.matOrnamentsBlue.opacity = opacity;
		this.matOrnamentsRed.opacity = opacity;
		this.matOrnamentsYellow.opacity = opacity;
		this.matOrnamentsWhite.opacity = opacity;
	}

	public setTreeLightsActive(active: boolean) 
	{
		this.matTreeLights.opacity = active ? OPACITY_LIGHTS_ON : OPACITY_LIGHTS_OFF;
	}
}