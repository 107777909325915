import { fromEvent, Subscription } from "rxjs";
import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, Enterable, Exitable, Initializable } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { Localization } from "../Localization";
import { Sfx } from "../Sfx";

const LOC_DUTCH: string = "nl-NL";
const LOC_ENGLISH: string = "en";
const LOC_FRENCH: string = "fr";
const LOC_GERMAN: string = "de";

@InAppState("language-select")
export class LanguageSelector extends Behaviour implements Initializable, Enterable, Exitable 
{
    private languageSelect: HTMLElement;

    private dutch: HTMLElement;
    private dutchClickSub: Subscription;

    private english: HTMLElement;
    private englishClickSub: Subscription;

    private french: HTMLElement;
    private frenchClickSub: Subscription;

    private german: HTMLElement;
    private germanClickSub: Subscription;

    private selected: HTMLElement;

    public initialize(): void 
    { 
        this.languageSelect = document.getElementById("language-select")!;
        // this.languageSelect.style.display = "none";

        const buttons = this.languageSelect.getElementsByClassName("button")!;
        this.dutch = buttons[0] as HTMLElement;
        this.english = buttons[1] as HTMLElement;
        this.german = buttons[2] as HTMLElement;
        this.french = buttons[3] as HTMLElement;
    }

    public enter(): void 
    {        
        this.languageSelect.classList.remove("hidden");
        
        this.dutchClickSub = fromEvent(this.dutch, "click").subscribe(() =>
        {
            this.setLoc(LOC_DUTCH);
        });        
        
        this.germanClickSub = fromEvent(this.german, "click").subscribe(() =>
		{
            this.setLoc(LOC_GERMAN);
		}); 

        this.englishClickSub = fromEvent(this.english, "click").subscribe(() =>
		{
            this.setLoc(LOC_ENGLISH);
		});        
           
        this.frenchClickSub = fromEvent(this.french, "click").subscribe(() =>
		{
            this.setLoc(LOC_FRENCH);
		});
    }

    public exit(): void 
    {		
        this.languageSelect.classList.add("hidden");

        this.dutchClickSub.unsubscribe();
        this.germanClickSub.unsubscribe();
        this.englishClickSub.unsubscribe();
        this.frenchClickSub.unsubscribe();
    }

    private setLoc(loc: string): void
    {
		Sfx.play("click.wav");
        switch(loc) 
        {
            case LOC_DUTCH:
                this.selected = this.dutch;
                break;

            case LOC_ENGLISH:
                this.selected = this.english;
                break;

            case LOC_FRENCH:
                this.selected = this.french;
                break;

            case LOC_GERMAN:
                this.selected = this.german;
                break;

            default:
                return;
        }

        Localization.setRegion(loc);
		AppStateMachine.requestNextState();
    }
}