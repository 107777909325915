import { Observable, Subject } from "rxjs";

export class Timeline
{
	private static entries: TimelineEntry[] = [];

	private static EventSubject: Subject<string> = new Subject<string>();
	public static EventObservable(): Observable<string>
	{
		return this.EventSubject;
	}

	private static time: number = 0;

	public static initialize(timelineData: any)
	{
		for (const [k, v] of Object.entries(timelineData))
		{
			var entry = new TimelineEntry(parseFloat(k), <string>v);
			this.entries.push(entry);
		}
	}

	public static reset(): void
	{
		this.time = 0;
		for (let i = 0; i < this.entries.length; i++)
		{
			this.entries[i].called = false
		}
	}

	public static update(dt: number): void
	{
		this.time += dt;
		for (let i = 0; i < this.entries.length; i++)
		{
			if (this.entries[i].called === true)
				continue;
			if (this.time < this.entries[i].time)
				continue;

			this.entries[i].called = true;
			this.EventSubject.next(this.entries[i].event);
		}
	}
}

class TimelineEntry
{
	time: number = -1;
	event: string = "";
	called: boolean = false;

	constructor(t: number, e: string)
	{
		this.time = t;
		this.event = e;
	}
}