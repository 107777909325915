import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, EnterableAsync, Exitable, Initializable, } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";
import { ThreeJsModelLoader } from "../ThreeJsModelLoader";
import { wait } from "../Utils";

@InAppState("opening")
export class OpeningUI extends Behaviour implements Initializable, EnterableAsync, Exitable
{
	private uiElement: HTMLElement;

	public initialize(): void
	{
		this.uiElement = document.getElementById("opening-ui")!;
	}

	async enterAsync(): Promise<void>
	{
		// await ThreeJsModelLoader.waitForLoad(WINDMOLENS_MODEL_PATH);
		await wait(2);
		while (ThreeJsModelLoader.IsLoading())
			await wait(0.1);
	}

	public enterAsyncDone(): void
	{
		AppStateMachine.requestNextState();
	}

	public exit(): void
	{
		this.uiElement.classList.add("hidden");
	}
}