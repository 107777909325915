import { WindowAccess } from "./Decorators";
import { DevicePermissions } from "./Permissions";
import { XR8DeviceEstimation } from "./XR8Interfaces";

@WindowAccess()
export class PermissionsHelper
{
	private static mainElement: HTMLElement;
	private static contentElement: HTMLElement;
	
	public static initPermissionsHelper(): void
	{
		var mainElement = document.getElementById("permissions-denied");
		if (mainElement === null)
			return;
		this.mainElement = mainElement;
		this.mainElement.classList.add("hidden");

		var contentElement = document.getElementById("content");
		if (contentElement === null)
			return;
		this.contentElement = contentElement;
	}

	private static showRootNode() : void
	{
		this.contentElement.classList.add("hidden");
		this.showElement(this.mainElement);
	}

	private static showElement(element: Element): void
	{
		element.classList.remove("hidden");
	}
	
	public static promptUserToChangeBrowserCameraSettings(permissions: DevicePermissions, deviceEstimation: XR8DeviceEstimation)
	{
		this.showRootNode();
		if (deviceEstimation.os === "Android")//ua.includes('Linux'))
		{
			this.showElement(this.mainElement.getElementsByClassName("android-camera")[0]);

			if (navigator.userAgent.includes('SamsungBrowser'))
			{
				this.showElement(this.mainElement.getElementsByClassName("android-samsung-browser")[0]);
			} 
			else // CHROME
			{
				this.showElement(this.mainElement.getElementsByClassName("android-chrome-browser")[0]);
			}
		}
		else
		{
			this.showElement(this.mainElement.getElementsByClassName("ios-camera-denied")[0]);
		}
	}
	
	public static promptUserToChangeBrowserMotionSettings(permissions: DevicePermissions, deviceEstimation: XR8DeviceEstimation)
	{
		if (permissions != null)
			permissions.removeMotionAndOrientation();
	
		this.showRootNode();
	
		if (deviceEstimation.osVersion.startsWith('12'))
		{
			this.showElement(this.mainElement.getElementsByClassName("ios-motion-denied-12")[0]);
		} else
		{
			this.showElement(this.mainElement.getElementsByClassName("ios-motion-denied")[0]);
		}
	}
}

PermissionsHelper.initPermissionsHelper();