import { DesktopSceneProvider, NullSceneProvider, SceneProvider, XR8SceneProvider } from "./SceneProvider";
import * as THREE from "three";
import { FullWindowCanvasFactory } from "./CustomFullWindowCanvas";
import { Observable, Subject } from "rxjs";
import { XR8ImageLoading, XR8ImageTrack, XR8OnCameraStatusChanged } from "./XR8Interfaces";
import { ThreeJsRoot } from "./ThreeJsRoot";
import { Quaternion, Vector2, WebGLRenderer } from "three";
import { AppConfig } from "./AppConfig";
import { CubemapAdditiveBlendingHack } from "./CubemapAdditiveBlendingHack";
declare const XR8: any;

export interface XRProvider
{
	initialize(renderCanvas: HTMLCanvasElement): void
	getSceneProvider(): SceneProvider;
	cameraStatusObservable(): Observable<string>;
	imageFoundObservable(): Observable<XR8ImageTrack>;
	imageUpdatedObservable(): Observable<XR8ImageTrack>;
	imageLostObservable(): Observable<XR8ImageTrack>;
	update(dt: number): void;
	run(): void;
	pause(): void;
	ready(): boolean;
	kill(): void;
}

export class DesktopXRProvider implements XRProvider
{
	private sceneProvider: SceneProvider;
	private cameraStatusSubject: Subject<string>;

	private keys: Map<string, boolean>;

	private mousedown: boolean;

	private mouseMovement: Vector2;

	constructor()
	{
		this.sceneProvider = new NullSceneProvider();
		this.cameraStatusSubject = new Subject<string>();
	}

	public initialize(renderCanvas: HTMLCanvasElement): void
	{
		this.sceneProvider = new DesktopSceneProvider(renderCanvas);
		this.mouseMovement = new Vector2();
		this.keys = new Map<string, boolean>([
			["w", false],
			["a", false],
			["s", false],
			["d", false],
			["q", false],
			["e", false],
		]);

		const me = this;

		document.addEventListener('mousedown', event =>
		{
			me.mousedown = true;
		});

		document.addEventListener('mousemove', event =>
		{
			this.mouseMovement.x = event.movementX;
			this.mouseMovement.y = event.movementY;
		});

		document.addEventListener('mouseup', event =>
		{
			me.mousedown = false;
		});

		document.addEventListener('keydown', function (event)
		{
			if (me.keys.has(event.key))
				me.keys.set(event.key, true)
		});

		document.addEventListener('keyup', function (event)
		{
			if (me.keys.has(event.key))
				me.keys.set(event.key, false)
		});

		this.sceneProvider.getSceneBundle().camera.translateZ(5);
	}

	public getSceneProvider(): SceneProvider
	{
		return this.sceneProvider;
	}

	public cameraStatusObservable(): Observable<string>
	{
		return this.cameraStatusSubject;
	}

	public imageFoundObservable(): Observable<XR8ImageTrack>
	{
		return new Subject<XR8ImageTrack>();
	}

	public imageUpdatedObservable(): Observable<XR8ImageTrack>
	{
		return new Subject<XR8ImageTrack>();
	}

	public imageLostObservable(): Observable<XR8ImageTrack>
	{
		return new Subject<XR8ImageTrack>();
	}

	public update(dt: number): void
	{
		var bundle = this.sceneProvider.getSceneBundle();
		bundle.renderer.render(bundle.scene, bundle.camera);

		var vector = new THREE.Vector3();
		vector.z += this.keys.get("w") ? -1 : 0;
		vector.z += this.keys.get("s") ? 1 : 0;
		vector.x += this.keys.get("a") ? 1 : 0;
		vector.x += this.keys.get("d") ? -1 : 0;
		vector.y += this.keys.get("q") ? 1 : 0;
		vector.y += this.keys.get("e") ? -1 : 0;

		if (this.mousedown)
		{
			bundle.camera.rotateY(this.mouseMovement.x * 0.1 * dt);
			bundle.camera.rotateX(this.mouseMovement.y * 0.1 * dt);
		}

		bundle.camera.translateOnAxis(vector, dt);
	}

	public run(): void
	{
	}

	public pause(): void
	{
	}

	public ready(): boolean
	{
		return true;
	}

	public kill(): void
	{
		// this.sceneProvider.getSceneBundle().scene.d
	}
}

export class XR8Provider implements XRProvider
{
	private sceneProvider: SceneProvider;
	private cameraStatusSubject: Subject<string>;
	private imageFoundSubject: Subject<XR8ImageTrack>;
	private imageUpdatedSubject: Subject<XR8ImageTrack>;
	private imageLostSubject: Subject<XR8ImageTrack>;

	private canvas: HTMLCanvasElement;

	private isReady: boolean = false;

	private recenterCounter: number = 0;
	private firstFound: boolean = false;

	constructor() 
	{
		this.sceneProvider = new NullSceneProvider();
		this.cameraStatusSubject = new Subject<string>();
		this.imageFoundSubject = new Subject<XR8ImageTrack>();
		this.imageUpdatedSubject = new Subject<XR8ImageTrack>();
		this.imageLostSubject = new Subject<XR8ImageTrack>();
	}

	public initialize(renderCanvas: HTMLCanvasElement): void
	{
		this.canvas = renderCanvas;

		// 8th wall needs this
		(window as any).THREE = THREE;

		var xrConfigObject =
		{
			enableLighting: false,
			enableWorldPoints: true,
			disableWorldTracking: false,
			imageTargets: []
		}

		if (AppConfig.get().useImageTracking)
		{
			var ar: any[] = AppConfig.get().defaultImageTargets;
			xrConfigObject.imageTargets = ar.map(v => {return v.name}) as [];
			console.log("config image targets: " + xrConfigObject.imageTargets);
		}

		XR8.XrController.configure(xrConfigObject);

		XR8.addCameraPipelineModules([
			XR8.GlTextureRenderer.pipelineModule(), // draws camera
			XR8.XrController.pipelineModule(), // enables 6DoF
			XR8.Threejs.pipelineModule(), // three js
		]);

		XR8.addCameraPipelineModule(FullWindowCanvasFactory().pipelineModule());

		XR8.addCameraPipelineModule({
			name: 'world-controller',

			onStart: () =>
			{
				this.sceneProvider = new XR8SceneProvider();
				var scene = this.sceneProvider.getSceneBundle().scene;
				scene.add(ThreeJsRoot.getRoot());
				scene.frustumCulled = false;
				var renderer = <WebGLRenderer>this.sceneProvider.getSceneBundle().renderer;
				renderer.shadowMap.enabled = true;
				renderer.outputEncoding = THREE.sRGBEncoding;
				// var surface = new THREE.Mesh(
				// 	new THREE.PlaneGeometry(3, 3, 1, 1),
				// 	// new THREE.MeshBasicMaterial({ color: 0x00ff00 })
				// 	new THREE.ShadowMaterial({
				// 		opacity: 0.7,
				// 	})
				// );
				// scene.add(surface);

				this.isReady = true;
			},
			onCameraStatusChange: (statusChanged: XR8OnCameraStatusChanged) =>
			{
				this.cameraStatusSubject.next(statusChanged.status);
			},
			onException: (error: Error) =>
			{
				console.error(error);
			}
		});

		var cubema = new CubemapAdditiveBlendingHack();
		cubema.init();
		XR8.addCameraPipelineModule(cubema.pipe());

		window.addEventListener("unload", () =>
		{
			console.log("bagabaga");
		});

		if (AppConfig.get().useImageTracking)
		{
			XR8.addCameraPipelineModule({
				name: "image-tracking",
				listeners: [
					{ event: 'reality.imageloading', process: ({ detail }: any) => this.imageLoading(detail.imageTargets) },
					{ event: 'reality.imagefound', process: ({ detail }: any) => this.imageFound(detail) },
					{ event: 'reality.imageupdated', process: ({ detail }: any) => this.imageUpdated(detail) },
					{ event: 'reality.imagelost', process: ({ detail }: any) => this.imageLost(detail) },
				]
			});
		}
	}

	private imageLoading(detail: XR8ImageLoading[]): void
	{
		console.log(detail[0]);
	}

	private imageFound(imageTrack: XR8ImageTrack): void
	{
		if (this.firstFound === false)
		{
			console.log("recentering");
			XR8.XrController.recenter();
			this.firstFound = true;
			return;
		}

		ThreeJsRoot.found(imageTrack);

		// if (AppConfig.get().showWhenRootTargetIsFound)
		// 	ThreeJsRoot.getRoot().visible = true;

		console.log("found");
		this.recenterCounter = 0;
		
		this.imageFoundSubject.next(imageTrack);
		this.imageUpdated(imageTrack);
	}

	private imageUpdated(imageTrack: XR8ImageTrack): void
	{
		// console.log(imageTrack);
		// console.log("showing target at " + imageTrack.position);

		this.imageUpdatedSubject.next(imageTrack);

		this.recenterCounter++;

		if (this.recenterCounter > 30)
		{
			this.recenterCounter = 0;
			// XR8.XrController.recenter();
		}

		ThreeJsRoot.updated(imageTrack);

		// if (imageTrack.name == AppConfig.get().rootTarget)
		// {
		// 	if (AppConfig.get().setRootTargetScale)
		// 	{
		// 		ThreeJsRoot.getRoot().scale.copy(
		// 			new THREE.Vector3(imageTrack.scale, imageTrack.scale, imageTrack.scale));
		// 	}

		// 	ThreeJsRoot.getRoot().position.copy(imageTrack.position);
		// 	var rootOffset = AppConfig.get().rootOffset;
		// 	ThreeJsRoot.getRoot().translateX(rootOffset.x);
		// 	ThreeJsRoot.getRoot().translateY(rootOffset.y);
		// 	ThreeJsRoot.getRoot().translateZ(rootOffset.z);

		// 	// var pos = new THREE.Vector3().copy(imageTrack.position);
		// 	// pos.x += rootOffset.x;
		// 	// pos.y += rootOffset.y;
		// 	// pos.z += rootOffset.z;
		// 	// ThreeJsRoot.getRoot().position.copy(pos);

		// 	var rootRotate = AppConfig.get().rootRotation;
		// 	var euler = new THREE.Euler(rootRotate.x, rootRotate.y, rootRotate.z);
		// 	var quat = new Quaternion().setFromEuler(euler);
		// 	var trackQuat = new THREE.Quaternion(imageTrack.rotation.x, imageTrack.rotation.y, imageTrack.rotation.z, imageTrack.rotation.w);
		// 	trackQuat = trackQuat.multiply(quat);
		// 	ThreeJsRoot.getRoot().setRotationFromQuaternion(trackQuat);
		// }
	}

	private imageLost(imageTrack: XR8ImageTrack): void
	{
		// if (AppConfig.get().hideWhenRootTargetIsLost)
		// 	ThreeJsRoot.getRoot().visible = false;

		ThreeJsRoot.lost(imageTrack);

		console.log("lost");

		this.recenterCounter = 0;

		this.imageLostSubject.next(imageTrack);
		console.log("hiding target");

		// this.firstFound = false;
	}

	public getSceneProvider(): SceneProvider
	{
		return this.sceneProvider;
	}

	public cameraStatusObservable(): Observable<string>
	{
		return this.cameraStatusSubject;
	}

	public imageFoundObservable(): Observable<XR8ImageTrack>
	{
		return this.imageFoundSubject;
	}

	imageUpdatedObservable(): Observable<XR8ImageTrack>
	{
		return this.imageUpdatedSubject;
	}

	public imageLostObservable(): Observable<XR8ImageTrack>
	{
		return this.imageLostSubject;
	}

	public update(dt: number): void
	{

	}

	public run(): void
	{
		if (this.ready())
		{
			if (XR8.isPaused())
				XR8.resume();
			return;
		}
		console.log("running xr!");
		XR8.run({ canvas: this.canvas });
	}

	public pause(): void
	{
		if (XR8.isPaused() === false)
			XR8.pause();
	}

	public ready(): boolean
	{
		return this.isReady;
	}

	public kill(): void
	{
		XR8.stop();
	}
}