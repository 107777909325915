import { Howl } from "howler";
import { VolumetricVideo } from "./behaviours/VolumetricVideo";
import { PauseManager } from "./PauseManager";
import { Timeline } from "./Timeline";

export class Sfx
{
	private static timelineSfxs: LoadedTimelineSfx[];
	private static loadedSfx: Map<string, Howl> = new Map<string, Howl>();

	public static initialize(timelineSfxs: TimelineSfxEntry[]): void
	{
		this.timelineSfxs = [];
		for (let i = 0; i < timelineSfxs.length; i++)
		{
			this.timelineSfxs.push(new LoadedTimelineSfx(timelineSfxs[i]));
		}
	}

	static async preloadTimelineSfxs(lowPower: boolean): Promise<void>
	{
		if (this.timelineSfxs.length === 0)
			return;
		for (let i = 0; i < this.timelineSfxs.length; i++)
		{
			if (this.timelineSfxs[i].entry.lowPower === false && lowPower)
				continue;
			var howl = await this.getHowl(this.timelineSfxs[i].entry.path, false, this.timelineSfxs[i].entry.volume, this.timelineSfxs[i].entry.stream);
			howl.stop();
			this.timelineSfxs[i].howl = howl;
		}

		Timeline.EventObservable().subscribe(e =>
		{
			for (let i = 0; i < this.timelineSfxs.length; i++)
			{
				if (this.timelineSfxs[i].entry.event !== e || this.timelineSfxs[i].hasPlayed || this.timelineSfxs[i].howl === undefined)
					continue;
				this.timelineSfxs[i].howl.play();
				this.timelineSfxs[i].hasPlayed = true;
			}
		});

		PauseManager.pausedObservable().subscribe(paused =>
		{
			this.setPaused(paused);
		});
	}

	static setPaused(paused: boolean): void
	{
		for (let i = 0; i < this.timelineSfxs.length; i++)
		{
			if (this.timelineSfxs[i].howl === undefined)
				continue;

			if (paused)
			{
				if (this.timelineSfxs[i].howl.playing() === false || this.timelineSfxs[i].isPaused)
					continue;
				this.timelineSfxs[i].howl.pause();
				this.timelineSfxs[i].isPaused = true;
			}
			else
			{
				if (this.timelineSfxs[i].isPaused === false || this.timelineSfxs[i].isPaused === false)
					continue;
				this.timelineSfxs[i].howl.play();
				this.timelineSfxs[i].isPaused = false;
			}
		}
	}

	static async preload(filename: string)
	{
		await this.getHowl(filename, false);
	}

	static play(fileName: string): void
	{
		if (this.loadedSfx.has(fileName))
		{
			this.loadedSfx.get(fileName)?.play();
			return;
		}

		this.getHowl(fileName, true);
	}

	static async getHowl(fileName: string, autoPlay: boolean = false, volume: number = 1, stream: boolean = false): Promise<Howl>
	{
		return new Promise<Howl>((resolve, reject) =>
		{
			var howl: Howl = new Howl({
				src: `sfx/${fileName}`,
				volume: volume,
				//html5: stream,
				onload: () => 
				{
					resolve(howl);
					this.loadedSfx.set(fileName, howl);
					if (autoPlay)
						howl.play();
				},
				onloaderror: () => 
				{
					//console.error("[Localization] Cannot load " + fileName);
					reject("[Sfx] Cannot load " + fileName);
				}
			});
		});
	}

	public static reset(): void
	{
		for (let i = 0; i < this.timelineSfxs.length; i++)
		{
			this.timelineSfxs[i].reset();
		}
	}

	public static update(dt: number): void
	{
		this.setPaused(!VolumetricVideo.checkBufferReady());
	}

	public static dispose(): void
	{
		this.loadedSfx.forEach((howl: Howl) =>
		{
			howl.unload();
		});
	}
}

interface TimelineSfxEntry
{
	name: string;
	event: string;
	path: string;
	volume: number;
	lowPower: boolean;
	stream: boolean;
}

class LoadedTimelineSfx
{
	public entry: TimelineSfxEntry;
	public howl: Howl;
	public hasPlayed: boolean;
	public isPaused: boolean;

	constructor(entry: TimelineSfxEntry) 
	{
		this.entry = entry;
		this.hasPlayed = false;
		if (this.entry.lowPower === undefined)
			this.entry.lowPower = false;
	}

	public reset(): void
	{
		this.hasPlayed = false;
		this.isPaused = false;
		if (this.howl !== undefined)
			this.howl.stop();
	}
}