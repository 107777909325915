import * as THREE from "three";
import { xrService } from "../../main";
import { Behaviour, Enterable, Initializable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { ThreeJsRoot } from "../ThreeJsRoot";

const modelPath: string = "models/pakhuis.json";

// @InAppState("Playing")
@DoNotLoadBehaviour()
export class Pakhuis extends Behaviour implements Initializable, Enterable
{
    private modelPath: string = "models/pakhuis.json";
    private object: THREE.Object3D;
    private mixer: THREE.AnimationMixer;

    initialize(): void    
    {
		console.log("afafaf");
        // const loader = new THREE.ObjectLoader();

        // console.log(modelPath);
        // console.log(this.modelPath);
        // this.modelPath = "models/pakhuis.json";
        // console.log(this.modelPath);

        // loader.load("models/pakhuis.json",
        //     (obj) =>
        //     {
        //         this.object = obj;
        //         this.mixer = new THREE.AnimationMixer(this.object);

        //         ThreeJsRoot.getRoot().add(this.object);
        //     },
        //     // onProgress callback
        //     function (xhr)
        //     {
        //         console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        //     },
        //     // onError callback
        //     function (err)
        //     {
        //         console.error('An error happened');
        //         console.error(err);
        //     });

        //so where do we play the animation?
    }

    enter(): void 
    {
        this.mixer.clipAction(this.object.animations[0]).play();
    }
}