import * as THREE from "three";
import { lowPower } from "../../main";
import { Behaviour, Enterable, Exitable, Initializable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { ConfettiCannon } from "../other/ConfettiCannon";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { Timeline } from "../Timeline";
import { VolumetricVideo } from "./VolumetricVideo";

@InAppState("scanning")
export class ConfettiController extends Behaviour implements Initializable, Enterable, Updatable, Exitable
{
	private confettiA: ConfettiCannon;
	private conffetiAGroup: THREE.Group;
	private updateConfettiA: boolean;

	private confettiB: ConfettiCannon;
	private conffetiBGroup: THREE.Group;
	private updateConfettiB: boolean;

	initialize(): void
	{
		this.conffetiAGroup = new THREE.Group();
		this.confettiA = new ConfettiCannon(this.conffetiAGroup, new THREE.Vector3(-5, 1, 0.5), 2);
		this.conffetiAGroup.renderOrder = 5;
		this.conffetiBGroup = new THREE.Group();
		this.confettiB = new ConfettiCannon(this.conffetiBGroup, new THREE.Vector3(-3, 1, 0.5), 2);
		this.conffetiBGroup.renderOrder = 5;

		const groupA: THREE.Object3D = this.conffetiAGroup;
		groupA.position.set(0.2, 0.25, -0.5);

		const groupB: THREE.Object3D = this.conffetiBGroup;
		groupB.position.set(0.26, 0.25, -0.5);

		Timeline.EventObservable().subscribe(e =>
		{
			if (lowPower)
				return;
			if (e === "confetti")
			{
				// console.log("AFAFAF");

				ThreeJsRoot.getTargetRoot("test-3").add(this.conffetiAGroup);
				ThreeJsRoot.getTargetRoot("test-3").add(this.conffetiBGroup);

				this.updateConfettiA = true;
				this.updateConfettiB = true;
			}
			// if (e === "confetti")
		});
	}

	enter(): void
	{
		this.reset();
	}

	update(dt: number): void
	{
		if (VolumetricVideo.checkBufferReady() === false)
			return;
		if (this.updateConfettiA)
			this.confettiA.update(dt);
		if (this.updateConfettiB)
			this.confettiB.update(dt);
	}

	exit(): void
	{
		this.reset();
	}

	private reset(): void
	{
		ThreeJsRoot.getRoot().remove(this.conffetiAGroup);
		ThreeJsRoot.getRoot().remove(this.conffetiBGroup);
		this.updateConfettiA = false;
		this.updateConfettiB = false;
		this.confettiA.reset();
		this.confettiB.reset();
	}
}