import { Howl, Howler } from "howler";
import { Observable, Subject } from "rxjs";
import { AppConfig } from "./AppConfig";

export class Localization
{
	private static locMap: Map<string, Loc>;
	private static currentRegion: string = "null";

	private static regionChangedSubject: Subject<string> = new Subject<string>();
	public static regionChangedObservable(): Observable<string> { return this.regionChangedSubject; }

	static initialize(locstrFiles: any): void
	{
		if (this.enabled() === false)
			return;

		this.locMap = new Map<string, Loc>();
		for (const [k, v] of Object.entries(locstrFiles))
		{
			var value = v as Loc;
			this.locMap.set(k, value);
		}

		this.setRegion(AppConfig.get().defaultRegion);
	}

	static setRegion(region: string): void
	{
		if (this.currentRegion === region || this.enabled() === false || region === "null" || region === undefined || region === null)
			return;

		if (this.locMap.has(region) === false)
		{
			console.error(`[Localization] No loc found for region '${region}'`);
			return;
		}

		this.currentRegion = region;
		var loc = this.locMap.get(this.currentRegion);
		var locstrs = Array.from(document.querySelectorAll("[locstr]")); // maybe cache this?
		for (let i = 0; i < locstrs.length; i++)
		{
			var key = locstrs[i].attributes.getNamedItem("locstr")?.value as string;

			var value = loc?.strings[key] as string;
			if (value === undefined || value.length === 0)
				value = "EMPTY_STRING"
			locstrs[i].innerHTML = value;
		}

		console.log(`[Localization] Region set to ${this.currentRegion}`);
		this.regionChangedSubject.next(this.currentRegion);
	}

	public static getString(key: string) : string
	{
		return this.locMap.get(this.currentRegion)?.strings[key] as string;
	}

	static enabled(): boolean
	{
		return AppConfig.get().enableLocalization;
	}

	static async getHowl(fileName: string, autoPlay: boolean = false): Promise<Howl>
	{
		return new Promise<Howl>((resolve, reject) =>
		{
			var howl: Howl = new Howl({
				src: `localized/${this.currentRegion}/${fileName}`,
				loop: false,
				// html5: true,
				onload: () => 
				{
					resolve(howl);
					if (autoPlay)
						howl.play();
				},
				onloaderror: () => 
				{
					//console.error("[Localization] Cannot load " + fileName);
					reject("[Localization] Cannot load " + fileName);
				}
			});
		});
	}
}

interface Loc
{
	strings: any; // should be Map<string, string> but that sort of doesn't work
}