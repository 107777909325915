import { Subscription } from "rxjs";
import { xrService } from "../../main";
import { AppConfig } from "../AppConfig";
import { Behaviour, Enterable, Exitable, Initializable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { Localization } from "../Localization";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { wait } from "../Utils";


@InAppState("scanning")
// @DoNotLoadBehaviour()
export class ScanningHelper extends Behaviour implements Initializable, Enterable, Exitable
{
	private root: HTMLElement;
	private scanningHelperLabel: HTMLElement;
	private scanningHelperAmount: HTMLElement;

	private imageTargetNames: string[];
	private imageTargetsFound: { name: string, found: boolean, element: HTMLElement, className: string, okElement: HTMLElement }[];
	private namesIndex: number = -1;

	private sub: Subscription;

	public initialize(): void 
	{
		var r = document.getElementById("scanning-helper");
		if (r === null)
			return;
		this.root = r;

		var label = document.getElementById("scanning-label");
		if (label === null)
			return;
		this.scanningHelperLabel = label;

		var amount = document.getElementById("scanning-amount");
		if (amount === null)
			return;
		this.scanningHelperAmount = amount;

		var ar: any[] = AppConfig.get().defaultImageTargets;
		this.imageTargetNames = ar.map(v => { return v.name }) as string[];

		this.imageTargetsFound = [];
		for (let i = 0; i < this.imageTargetNames.length; i++)
		{
			var className = this.imageTargetNames[i] + "-marker";
			var el = this.root.getElementsByClassName(this.imageTargetNames[i] + "-marker")[0] as HTMLElement;
			if (el === null)
				continue;
			var okEl = el.getElementsByClassName(className + "-ok")[0] as HTMLElement;
			// el.classList.add(className + "-ok");
			this.imageTargetsFound.push({
				name: this.imageTargetNames[i],
				found: false,
				element: el,
				className: className,
				okElement: okEl
			});
			el.classList.add("hidden");
			okEl.classList.add("hidden");
		}

		// this.setLabelString();
	}

	public enter(): void 
	{
		this.setLabelString();

		if (ThreeJsRoot.allImageTargetsFound())
		{
			this.done();
			return;
		}

		this.root.classList.remove("hidden");

		this.namesIndex = -1;
		this.scanNext();

		this.sub = xrService.imageFoundObservable().subscribe(detail =>
		{
			this.found(detail.name);
		});
	}

	private async found(name: string): Promise<void>
	{
		this.setLabelString();
		var foundIter = -1;
		for (let i = 0; i < this.imageTargetsFound.length; i++)
		{
			if (this.imageTargetsFound[i].name === name)
			{
				foundIter = i;
				this.imageTargetsFound[i].found = true;
				if (foundIter === this.namesIndex)
				{
					this.imageTargetsFound[i].element.classList.remove(this.imageTargetsFound[i].className);
					this.imageTargetsFound[i].okElement.classList.remove("hidden");
					this.imageTargetsFound[i].okElement.getElementsByClassName("vink")[0].classList.remove("hidden");
				}
				break;
			}
		}

		if (foundIter === this.namesIndex)
			await wait(1);

		this.imageTargetsFound[foundIter].element.classList.add("hidden");

		if (ThreeJsRoot.allImageTargetsFound())
		{
			this.done();
			return;
		}

		if (this.namesIndex === foundIter)
			this.scanNext();
	}

	public exit(): void 
	{
		this.sub?.unsubscribe();
	}

	private scanNext(): void
	{
		this.namesIndex++;
		while (this.imageTargetsFound[this.namesIndex].found)
		{
			if (ThreeJsRoot.allImageTargetsFound())
				return;
			this.namesIndex++;
			if (this.namesIndex >= this.imageTargetsFound.length)
				this.namesIndex = 0;
		}

		this.imageTargetsFound[this.namesIndex].element.classList.remove("hidden");
		this.imageTargetsFound[this.namesIndex].element.classList.remove(this.imageTargetsFound[this.namesIndex].className + "-ok");
	}

	private done(): void
	{
		this.root.classList.add("hidden");
		this.sub?.unsubscribe();
	}

	private setLabelString(): void
	{
		var amount = ThreeJsRoot.foundAmount().toString() + " / " + this.imageTargetNames.length;
		var label = this.scanningHelperLabel.children.item(0);
		if (label !== null)
			label.innerHTML = Localization.getString("search-marker-text")
		var amountLabel = this.scanningHelperAmount.children.item(0);
		if (amountLabel !== null)
			amountLabel.innerHTML = amount.toString();
	}
}