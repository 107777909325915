import { AppStateMachine } from "../AppStateMachine";
import { Behaviour, Enterable, Exitable, Initializable, } from "../BehaviourInterfaces";
import { InAppState } from "../Decorators";

@InAppState("tutorial")
export class TutorialScreen extends Behaviour implements Initializable, Enterable, Exitable
{
	private uiElement: HTMLElement;

	public initialize(): void
	{
		this.uiElement = document.getElementById("tutorial-ui")!;
	}

	public enter(): void
	{
		this.uiElement.classList.remove("hidden");
	}

	public exit(): void
	{
		this.uiElement.classList.add("hidden");
	}
}