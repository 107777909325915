const _temp0 = require("./AddCube.ts");
const _temp1 = require("./AnotherClass.ts");
const _temp2 = require("./BackButton.ts");
const _temp3 = require("./ChristmasLights.ts");
const _temp4 = require("./ChristmasTreeContour.ts");
const _temp5 = require("./ConfettiController.ts");
const _temp6 = require("./HouseContour.ts");
const _temp7 = require("./ImageTarget.ts");
const _temp8 = require("./LanguageSelector.ts");
const _temp9 = require("./OpeningUI.ts");
const _temp10 = require("./Pakhuis.ts");
const _temp11 = require("./PermissionsUI.ts");
const _temp12 = require("./ScanningHelper.ts");
const _temp13 = require("./Snow.ts");
const _temp14 = require("./SomeClass.ts");
const _temp15 = require("./SystemsController.ts");
const _temp16 = require("./TimelineExample.ts");
const _temp17 = require("./TutorialBackButton.ts");
const _temp18 = require("./TutorialScreen.ts");
const _temp19 = require("./VolumetricVideo.ts");
const _temp20 = require("./WindmillAnimEvent.ts");
const _temp21 = require("./XRInit.ts");
module.exports = {
  "AddCube": _temp0,
  "AnotherClass": _temp1,
  "BackButton": _temp2,
  "ChristmasLights": _temp3,
  "ChristmasTreeContour": _temp4,
  "ConfettiController": _temp5,
  "HouseContour": _temp6,
  "ImageTarget": _temp7,
  "LanguageSelector": _temp8,
  "OpeningUI": _temp9,
  "Pakhuis": _temp10,
  "PermissionsUI": _temp11,
  "ScanningHelper": _temp12,
  "Snow": _temp13,
  "SomeClass": _temp14,
  "SystemsController": _temp15,
  "TimelineExample": _temp16,
  "TutorialBackButton": _temp17,
  "TutorialScreen": _temp18,
  "VolumetricVideo": _temp19,
  "WindmillAnimEvent": _temp20,
  "XRInit": _temp21
}