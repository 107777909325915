import * as THREE from "three";
import { Behaviour, Enterable, Exitable, Initializable, Updatable } from "../BehaviourInterfaces";
import { DoNotLoadBehaviour, InAppState } from "../Decorators";
import { ThreeJsRoot } from "../ThreeJsRoot";
import { xrService } from "../../main";
import { fromEvent, Subscription, take } from "rxjs";
import { XR8ImageTrack } from "../XR8Interfaces";
import { ThreeJsModelLoader } from "../ThreeJsModelLoader";
import { Vector3 } from "three";
import { AppStateMachine } from "../AppStateMachine";
import { AppConfig } from "../AppConfig";

export const PAKHUIS_MODEL_PATH: string = "models/pakhuis-v2.json";
export const WINDMOLENS_MODEL_PATH: string = "models/kerstpakket.json";
// const IMAGE_TARGET_NAME: string = "model-target";

@InAppState("scanning")
// @DoNotLoadBehaviour()
export class ImageTarget extends Behaviour implements Initializable, Updatable, Enterable, Exitable
{
	// private model: THREE.Object3D;
	// private mixer: THREE.AnimationMixer;
	// private action: THREE.AnimationAction;

	// private windmolensObj: THREE.Object3D;
	// private windmolensMixer: THREE.AnimationMixer;
	// private windmolensAnim: THREE.AnimationAction;

	// private modelsLoaded: boolean;
	private directionalLightAdded: boolean;

	// private foundSubscription: Subscription;
	// private lostSubscription: Subscription;

	private uiElement: HTMLElement;

	// private replaySectionElement: HTMLElement;
	private bottomSectionElement: HTMLElement;

	// private replayButtonElement: HTMLElement;
	// private replayButtonSubscription: Subscription;

	private onTargetFoundSub: Subscription;
	private onTargetUpdatedSub: Subscription;

	public initialize(): void 
	{
		// this.modelsLoaded = false;
		this.directionalLightAdded = false;

		const ambient = new THREE.AmbientLight(0x404040);
		ThreeJsRoot.getRoot().add(ambient);

		// ThreeJsModelLoader.loadFromUrl(WINDMOLENS_MODEL_PATH)
		//     .then(obj => 
		//     {
		//         this.windmolensObj = obj;
		//         this.windmolensObj.scale.set(0.1, 0.1, 0.1);
		//         this.windmolensMixer = new THREE.AnimationMixer(this.windmolensObj);
		//         this.windmolensAnim = this.windmolensMixer.clipAction(this.windmolensObj.animations[0]);

		//         // ThreeJsRoot.getRoot().add(this.windmolensObj);

		// 		// this.windmolensObj.position.set(1,0,1);
		//     });

		/*ThreeJsModelLoader.loadFromUrl(PAKHUIS_MODEL_PATH)
			.then(obj =>
			{
				this.model = obj;
				this.model.scale.copy(new Vector3(0.1, 0.1, 0.1));

				this.mixer = new THREE.AnimationMixer(this.model);
				this.action = this.mixer.clipAction(this.model.animations[0]);
				this.modelLoaded = true;
				ThreeJsRoot.getRoot().add(this.model);
			});*/

		this.uiElement = document.getElementById("model-ui")!;
		// this.uiElement.style.display = "none";

		// this.replayButtonElement = this.uiElement.getElementsByClassName("replay-button")[0] as HTMLElement;

		// this.replaySectionElement = document.getElementById("replay-section")!;
		// this.replaySectionElement.style.display = "none";
		this.bottomSectionElement = document.getElementById("bottom-section")!;
	}

	public enter(): void
	{
		// this.modelsLoaded = true;

		if (!this.directionalLightAdded)
		{
			this.directionalLightAdded = true;
			const directional = new THREE.DirectionalLight(0xffffff, 100);
			xrService.getSceneProvider().getSceneBundle().camera.add(directional);
		}

		this.uiElement.classList.remove("hidden");

		if (ThreeJsRoot.allImageTargetsFound() === false)
		{
			this.onTargetFoundSub = xrService.imageFoundObservable().subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
			this.onTargetUpdatedSub = xrService.imageUpdatedObservable().pipe(take(1)).subscribe((detail: XR8ImageTrack) => this.onImageTarget(detail));
		}
		else
		{
			// this.replaySectionElement.style.display = "";
			document.getElementById("scanning-helper")?.classList.add("hidden");
			document.getElementById("scanning-label")?.classList.add("hidden");
		}

		// this.foundSubscription = xrService.imageFoundObservable().subscribe((detail: XR8ImageTrack) =>
		// {

		// });

		// this.lostSubscription = xrService.imageLostObservable().subscribe((detail: XR8ImageTrack) =>
		// {
		//     // if (detail.name == IMAGE_TARGET_NAME) 
		//     // {
		//     //     this.onImageTargetLost();
		//     // }
		// });
	}

	private onImageTarget(detail: XR8ImageTrack): void
	{
		// console.log("gaga found");
		if (ThreeJsRoot.allImageTargetsFound())//(detail.name == AppConfig.get().rootTarget)
		{
			// this.windmolensAnim.play();

			// this.replaySectionElement.style.display = "";
			document.getElementById("scanning-helper")?.classList.add("hidden");
			document.getElementById("scanning-label")?.classList.add("hidden");

			this.onTargetFoundSub.unsubscribe();
			this.onTargetUpdatedSub.unsubscribe();

			// this.replayButtonElement.classList.remove("disable-button");
			// this.replayButtonSubscription = fromEvent(this.replayButtonElement, "click").subscribe(() =>
			// {
			// 	AppStateMachine.reloadCurrentState();
			//     // this.windmolensAnim.stop();
			//     // this.windmolensAnim.play();
			// });
		}
	}

	public exit(): void 
	{
		this.onImageTargetLost();

		this.onTargetFoundSub?.unsubscribe();
		this.onTargetUpdatedSub?.unsubscribe();
		// this.replayButtonSubscription?.unsubscribe();

		this.uiElement.classList.add("hidden");
	}

	public update(dt: number): void 
	{
		// if (!this.modelsLoaded) 
		// {
		//     return;
		// }

		// this.windmolensMixer.update(dt);
	}

	private onImageTargetLost(): void 
	{
		// this.windmolensAnim.stop();

		// this.replaySectionElement.style.display = "none";
		this.bottomSectionElement.style.display = "";

		// this.replayButtonSubscription?.unsubscribe();
		// this.replayButtonElement.classList.add("disable-button");
	}
}