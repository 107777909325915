0: "start"
4: "snow"
9: "vehicles"
10.33: "toeter"
17: "confetti"
17.01: "snowball"
18: "bikePass"
24: "windmills"
32: "lightSwitch"
33: "piek"
36: "lights"