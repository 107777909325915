- name: "start"
  event: "start"
  path: "music.mp3"
  volume: 0.025
  lowPower: true
  stream: true
- name: "confettiboom"
  event: "confetti"
  path: "Confetti.mp3"
  volume: 1
  stream: false
- name: "bike"
  event: "bikePass"
  path: "BikeBell.mp3"
  volume: 0.75
  stream: false
# - name: "toeter"
#   event: "toeter"
#   path: "toeter.mp3"
#   volume: 0.01
#   stream: false
- name: "light"
  event: "lightSwitch"
  path: "LightSwitch.mp3"
  volume: 0.33
  stream: false