strings:
  intro-title: "<span style=\"font-weight: bold\">CHRISTMAS VILLAGE<span style=\"color: #FF6600\">.</span></span>"
  intro-subtitle: "Check the halls."
  intro-body: "<span style=\"font-weight: bold\">Welcome</span> to the Coolblue Christmas village! Turn up your volume, stop singing carols for a minute, and dress warmly before going outside. "
  intro-buttontext: "<span style=\"font-weight: bold\">Let’s get this snow started</span>"
  camera-denied-title: "<span style=\"font-weight: bold\">TRY AGAIN<span style=\"color: #FF6600\">.</span></span>"
  camera-denied-subtitle: "You’re really Claus."
  camera-denied-body: "Ho-ho-hold on, you still need to give the Coolblue App access to your camera. Here’s how you do"
  camera-denied-list1: "Go to <span style=\"font-weight: bold\">settings</span>"
  camera-denied-list2: "Select <span style=\"font-weight: bold\">camera access</span>"
  camera-denied-list3: "Select <span style=\"font-weight: bold\">allow</span>"
  camera-denied-list4: "Reload the app"
  camera-denied-buttontext: "<span style=\"font-weight: bold\">Reload the app</span>"
  search-marker-text: "Scan the parts"
  replay: "Replay"
